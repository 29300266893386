import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MButtonModule } from '@mercadona/components/button';
import { MCardModule } from '@mercadona/components/card';
import { MContextMenuModule } from '@mercadona/components/context-menu';
import { MSpinnerModule } from '@mercadona/components/spinner';
import { MTranslateModule } from '@mercadona/core/translate';
import { MIconModule } from '@mercadona/icons';

import { CardListSmartComponent } from './card-list-smart.component';

/**
 * CardListSmartModule
 */
@NgModule({
  declarations: [CardListSmartComponent],
  imports: [
    CommonModule,
    MCardModule,
    MTranslateModule,
    MIconModule,
    DragDropModule,
    MButtonModule,
    MContextMenuModule,
    MSpinnerModule
  ],
  exports: [CardListSmartComponent]
})
export class CardListSmartModule {}

import { SocietyResourceCollectionResponseInterface } from '../../interfaces/society-resource/society-resource-collection-response.interface';
import { PaginationModel } from '../pagination.model';
import { SocietyResourceCollectionModel } from './society-resource-collection.model';

/**
 * SocietyResourceCollectionResponseModel
 */
export class SocietyResourceCollectionResponseModel {
  data: SocietyResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: SocietyResourceCollectionResponseInterface) {
    this.data = new SocietyResourceCollectionModel(data.data);
    this.pagination = new PaginationModel(data.pagination);
  }
}

import { DepartmentResourceResponseInterface } from '../../interfaces/departament-resource/department-resource-response.interface';
import { DepartmentResourceModel } from './department-resource.model';

/**
 * DepartmentResourceResponseModel
 */
export class DepartmentResourceResponseModel {
  data?: DepartmentResourceModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: DepartmentResourceResponseInterface) {
    this.data = data.data ? new DepartmentResourceModel(data.data) : undefined;
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { MSafeAny } from '@mercadona/common/private';

import { Repository } from '../abstract.repository';
/**
 * States stage
 */
@Injectable({
  providedIn: 'root'
})
export class StatesStageRepository extends Repository {
  baseUrl = `${this.basePath}/states`;

  constructor(injector: Injector) {
    super(injector);
  }

  getStatesStage(): Observable<string[]> {
    return this.doRequest<string[]>('get', `${this.baseUrl}`, undefined).pipe();
  }

  getStageStatesTransitions(): Observable<MSafeAny> {
    return this.doRequest<string[]>('get', `${this.baseUrl}/transitions`, undefined).pipe();
  }
}

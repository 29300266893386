import { MLoggerLevel } from '@mercadona/core/logger';
import { MPlatformEnvironment } from '@mercadona/core/platform';
import { MTelemetryConfig, MTraces } from '@mercadona/core/telemetry';
import { MEnviroment } from '@mercadona/core/utils/environment';

/**
 * Define here localhost environment variables.
 * Remember to copy these variables to the chart files for the environment (dev,itg,pre,pro)
 */
const localEnvironment = {
  production: false,
  logLevel: MLoggerLevel.TRACE,
  env: 'local' as MPlatformEnvironment,
  postLogoutUrl: '',
  redirectUrl: undefined,
  configToken: {
    clientId: '1a36f9f6-fcf0-4c01-af30-fac4a1245312',
    stsServer: 'https://fed.itgmercadona.com/adfs',
    audience: 'audience://mercadonainnerapis/audience/portalviajes/v1/'
  },
  apiRootUrl: {
    url: 'https://portalviajes-back.dev.srv.mercadona.com/portalviajes/api/v1',
    scope: [],
    resource: 'audience://mercadonainnerapis/audience/portalviajes/v1/'
  },
  telemetryConfig: {
    url: 'https://otelcol-front.dev.gcp.mercadona.com/v1/traces',
    traces: MTraces.DEFAULT
  } as MTelemetryConfig
};

const envInstance = new MEnviroment('portalviajes', localEnvironment);

export const environment = envInstance.environment;

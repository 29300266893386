import { BehaviorSubject } from 'rxjs';
export enum RolesEnum {
  ROLE_DIRECTOR = 'ROLE_DIRECTOR',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_PRESIDENCY = 'ROLE_PRESIDENCY',
  ROLE_TRAVELLER = 'ROLE_TRAVELLER',
  ROLE_EMPTY = 'EMPTY',
  ROLE_ADMIN_EXT = 'ROLE_ADMIN_EXT'
}

export const CURRENT_ROLES = new BehaviorSubject<string[]>([]);

export const ROLES = {
  [RolesEnum.ROLE_ADMIN]: RolesEnum.ROLE_ADMIN,
  [RolesEnum.ROLE_DIRECTOR]: RolesEnum.ROLE_DIRECTOR,
  [RolesEnum.ROLE_PRESIDENCY]: RolesEnum.ROLE_PRESIDENCY,
  [RolesEnum.ROLE_TRAVELLER]: RolesEnum.ROLE_TRAVELLER,
  [RolesEnum.ROLE_ADMIN_EXT]: RolesEnum.ROLE_ADMIN_EXT
};

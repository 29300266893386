import { Injectable } from '@angular/core';

import { MIconInterface, MIconService } from '@mercadona/icons';
import * as svgs from '@mercadona/icons/svg';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private icons: MIconInterface[] = [
    svgs.navigationMenuSvg,
    svgs.arrowDown1Svg,
    svgs.logout1Svg,
    svgs.singleManSvg,
    svgs.binSvg,
    svgs.navigationMenuVerticalBoldSvg,
    svgs.arrowLeft1Svg,
    svgs.printTextSvg,
    svgs.pencil1Svg,
    svgs.removeBoldSvg,
    svgs.planeTrip1Svg,
    svgs.planeTakeOffSvg,
    svgs.planeLandSvg,
    svgs.calendar3BoldSvg,
    svgs.filter1Svg,
    svgs.addBoldSvg,
    svgs.houseSvg,
    svgs.keyboardArrowRightSvg,
    svgs.view1Svg,
    svgs.informationCircleSvg,
    svgs.checkCircle1Svg,
    svgs.taskListApproveSvg,
    svgs.alertTriangleSvg,
    svgs.carSvg,
    svgs.carBoldSvg,
    svgs.dragAndDropSvg,
    svgs.dataTransferHorizontalSvg,
    svgs.viewOffSvg,
    svgs.search1BoldSvg,
    svgs.taskListApproveSvg,
    svgs.singleNeutralBoldSvg,
    svgs.singleNeutralCircleBoldSvg,
    svgs.pencil1Svg,
    svgs.sendEmail2Svg
  ];

  constructor(private readonly mIconService: MIconService) {}

  initIcons(): void {
    this.mIconService.registerIcon(...this.icons);
  }
}

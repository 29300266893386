/**
 * SocietyResourceModel
 */
import { SocietyResourceInterface } from '../../interfaces/society-resource/society-resource.interface';

export class SocietyResourceModel {
  id?: number;
  code?: string;
  name?: string;
  description?: string;
  isChargesManaged?: boolean;
  isTravelSociety?: boolean;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: SocietyResourceInterface) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.description = data.description;
    this.isChargesManaged = data.isChargesManaged;
    this.isTravelSociety = data.isTravelSociety;
  }
}

import { LinksBCD } from './links-bcd.const';
import { RolesEnum } from './roles.const';

export interface MenuInterface {
  label: string;
  link?: string;
  url?: string;
  disabled?: boolean;
  selected?: boolean;
  roles: RolesEnum[];
  child?: MenuInterface[];
  onlyVisibleForRetail?: boolean;
}

export const MENU = [
  {
    label: 'BOOKINGS.G200_BOOKING',
    roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY, RolesEnum.ROLE_TRAVELLER],
    child: [
      {
        label: 'BOOKINGS.MY_BOOKINGS',
        link: '/g200/bookings',
        roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY, RolesEnum.ROLE_TRAVELLER]
      },
      {
        label: 'BOOKINGS.G200_JOURNEYS',
        link: '/g200/journeys',
        roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY, RolesEnum.ROLE_TRAVELLER]
      }
    ],
    onlyVisibleForRetail: true
  },
  {
    label: 'ADMINISTRATION.ADMINISTRATION',
    roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_ADMIN_EXT],
    child: [
      {
        label: 'ADMINISTRATION.NOTICES.MENU_TITLE',
        link: '/administration/notices',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.LINKS.MENU_TITLE',
        link: '/administration/links',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.USERS',
        link: '/administration/users',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.INFORMATION.MENU_TITLE',
        link: '/administration/information',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.INDICATORS.MENU_TITLE',
        link: '/administration/indicators',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.NOTIFICATIONS.MENU_TITLE',
        link: '/administration/notifications',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.TRIPCARE',
        url: 'https://www.tripcare.es/bcdsecurity/',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'OWN-FLEET.MENU_VEHICLES',
        link: '/administration/vehicles',
        roles: [RolesEnum.ROLE_ADMIN]
      },
      {
        label: 'ADMINISTRATION.REPORTING',
        url: LinksBCD.REPORTING,
        roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_ADMIN_EXT]
      },
      {
        label: 'ADMINISTRATION.INQUIRY_BILLING',
        url: LinksBCD.INQUIRY_BILLING,
        roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_ADMIN_EXT]
      }
    ]
  },
  {
    label: 'OWN-FLEET.TITLE',
    link: '/own-fleet',
    roles: [RolesEnum.ROLE_ADMIN, RolesEnum.ROLE_DIRECTOR, RolesEnum.ROLE_PRESIDENCY]
  }
];

/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToastService } from '@services/toast/toast.service';

import { MSafeAny } from '@mercadona/common/private';

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService) {}

  intercept(req: HttpRequest<MSafeAny>, next: HttpHandler): Observable<HttpEvent<MSafeAny>> {
    const ignoreError = Boolean(req.params.has('$ignoreError'));
    if (ignoreError) {
      const params = req.params.delete('$ignoreError');
      req = req.clone({ params });
    }

    return next.handle(req).pipe(catchError((error) => this.handleError(error, ignoreError)));
  }

  private handleError(error: HttpErrorResponse, ignoreError: boolean): Observable<MSafeAny> {
    if (!ignoreError && error?.error?.error?.description) {
      this.toastService.openSuccess(error.error.error.description, false);
      return of(error);
    }
    return throwError(() => error);
  }
}

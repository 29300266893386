/**
 * JourneySimpleResourceModel
 */
import { JourneySimpleResourceInterface } from '@pages/g200/pages/journeys/interface/journey-simple-resource.interface';

export class JourneySimpleResourceModel {
  id?: number;
  code?: string;
  isPresidential?: boolean;
  departmentIdRequested?: number;
  petitionerId?: number;
  isHighlighted?: boolean;
  highlightDescription?: string;
  airplaneId?: number;
  isAnotherAirplane?: boolean;
  anotherAirplaneName?: string;
  anotherAirplaneAvailableSeats?: number;

  constructor(data: JourneySimpleResourceInterface) {
    this.id = data.id;
    this.code = data.code;
    this.isPresidential = data.isPresidential;
    this.departmentIdRequested = data.departmentIdRequested;
    this.petitionerId = data.petitionerId;
    this.isHighlighted = data.isHighlighted;
    this.highlightDescription = data.highlightDescription;
    this.airplaneId = data.airplaneId;
    this.isAnotherAirplane = data.isAnotherAirplane;
    this.anotherAirplaneName = data.anotherAirplaneName;
    this.anotherAirplaneAvailableSeats = data.anotherAirplaneAvailableSeats;
  }
}

import { formatDate } from '@angular/common';

import { PersonResourceInterface } from '@interfaces/person-resource/person-resource.interface';

import { MTimepickerSelection } from '@mercadona/components/timepicker';
import { MSupportedLanguages } from '@mercadona/core/translate';

export const parseIntOrUndefined = (numberString: string): number | undefined =>
  (numberString && Number.parseInt(numberString, 10)) || undefined;

export const parseDateStr = (dateString?: string): Date => (dateString ? new Date(dateString) : new Date());

export const parseDateFormat = (date: Date | undefined, locale: MSupportedLanguages): string =>
  date ? formatDate(date, 'yyyy-MM-ddTHH:mm:ss', locale) : '';

export const parseDateFormat2 = (date: Date, locale: MSupportedLanguages): string =>
  date ? formatDate(date, 'HH:mm - dd/MM/yyyy', locale) : '';

export const parseDateFormat3 = (date: Date | undefined, locale: MSupportedLanguages): string =>
  date ? formatDate(date, 'yyyy-MM-dd', locale) : '';

export const parseStrDateFormat2 = (date: string, locale: MSupportedLanguages): string =>
  date ? parseDateFormat2(new Date(date), locale) : '';

export const parseDateNoTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  const yyyy: number = date.getFullYear();
  const mm: number = date.getMonth() + 1; // Months start at 0!
  const dd: number = date.getDate();
  let mmStr: string = mm.toString();
  let ddStr: string = dd.toString();
  if (dd < 10) {
    ddStr = '0' + dd;
  }
  if (mm < 10) {
    mmStr = '0' + mm;
  }
  return ddStr + '/' + mmStr + '/' + yyyy;
};

export const setTime = (date: string | undefined, time: MTimepickerSelection | undefined): Date => {
  const newDate = parseDateStr(date);
  const hours = time?.hours || 0;
  const minutes = time?.minutes || 0;
  newDate.setHours(hours, minutes);
  return newDate;
};

export const parseBoolean = (bool?: boolean): boolean => Boolean(bool);

export const parseNumber = (originalNumber?: number): number => (originalNumber ? originalNumber : -1);

export const parseString = (originalString?: string): string => (originalString ? originalString : '');

export const parseAirplaneId = (idString: string = ''): number | string => {
  const idNumber: number = parseInt(idString, 10);
  return isNaN(idNumber) ? idString : idNumber;
};

export const formTextForRequesterName = (person?: PersonResourceInterface): string =>
  formatFullName(person?.name, person?.firstSurname, person?.secondSurname);

export const formatFullName = (name: string = '', firstSurname: string = '', secondSurname: string = ''): string =>
  `${name} ${firstSurname} ${secondSurname}`;

import { SocietyResourceCollectionInterface } from '../../interfaces/society-resource/society-resource-collection.interface';
import { SocietyResourceModel } from './society-resource.model';

/**
 * SocietyResourceCollectionModel
 */
export class SocietyResourceCollectionModel {
  societies?: SocietyResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: SocietyResourceCollectionInterface) {
    this.societies = data.societies ? data.societies.map((society) => new SocietyResourceModel(society)) : [];
  }
}

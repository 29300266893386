import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PersonExtendedResourceInterface } from '@interfaces/person-extended/person-extended-resource.interface';
import { PersonResourceResponseInterface } from '@interfaces/person-resource/person-resource-response.interface';
import { PersonSimpleResourceRequestInterface } from '@interfaces/person-resource/person-simple-resource-request.interface';
import { AppManagerService } from '@services/app-manager/app-manager.service';
import { GlobalCacheService } from '@services/global-cache/global-cache.service';
import { PersonsService } from '@services/persons/persons.service';
import { ToastService } from '@services/toast/toast.service';
import { UsersService } from '@services/users/users.service';
import { countryCodes } from '@shared/consts/country-codes';
import { formTextForRequesterName } from '@shared/utils/normalizers/normalizers';
import { WhiteSpaceValidator } from '@shared/validators/validators';

import { MTranslateService } from '@mercadona/core/translate';

@Component({
  selector: 'app-user',
  templateUrl: './user-profile-page.component.html',
  styleUrls: ['./user-profile-page.component.scss']
})
export class UserProfilePageComponent implements OnInit {
  departmentName!: string;
  fullName!: string;
  isLoading: boolean = true;
  languages: KeyValue<string, string>[] = [];
  phonePrefixes = countryCodes;
  today: Date = new Date();
  user!: PersonExtendedResourceInterface;
  userFormGroup!: FormGroup;
  userNameInitials: string | undefined = undefined;

  constructor(
    private readonly fb: FormBuilder,
    private readonly globalCacheService: GlobalCacheService,
    private readonly mTranslateService: MTranslateService,
    private readonly personsService: PersonsService,
    private readonly toastService: ToastService,
    private readonly userService: UsersService,
    private readonly appManagerService: AppManagerService
  ) {
    this.mTranslateService.selectTranslate('LANGUAGES.SPANISH').subscribe((spanish) => {
      this.languages = [
        { key: 'es-ES', value: spanish },
        { key: 'pt-PT', value: this.mTranslateService.translate('LANGUAGES.PORTUGUESE') }
      ];
    });
  }

  ngOnInit(): void {
    this.appManagerService.updateHeaderTitle('USER_PROFILE.TITLE');
    this.userService.getUserInfo().subscribe((userInfo: PersonResourceResponseInterface) => {
      if (userInfo.data) {
        this.user = {
          ...userInfo.data,
          phoneNumberPrefix: this.getPrefix(userInfo.data.phoneNumberPrefix)
        };

        this.fullName = formTextForRequesterName(this.user);
        this.setUserNameAndInitials(userInfo);
        this.initForm();
        this.setDepartmentName();
      }

      this.isLoading = false;
    });
  }

  setUserNameAndInitials(userInfo: PersonResourceResponseInterface) {
    const name: string | unknown = userInfo.data?.name;
    const firstSurname: string | unknown = userInfo.data?.firstSurname;
    const secondSurname: string | unknown = userInfo.data?.secondSurname;
    const userName = `${name} ${firstSurname} ${secondSurname}`;
    this.userNameInitials = userName
      .split(' ', 3)
      .map((username) => username[0])
      .reduce((initials, newInitial) => initials + newInitial);
  }

  handleUpdate(): void {
    if (this.userFormGroup.dirty && this.userFormGroup.valid) {
      this.userFormGroup.markAsPristine();
      this.isLoading = true;
      this.personsService.updatePerson(this.user.id as number, this.getFormData()).subscribe(() => {
        this.isLoading = false;
        this.toastService.openSuccess();
      });
    }
  }

  private getFormData(): PersonSimpleResourceRequestInterface {
    if (isNaN(new Date(this.userFormGroup.controls.passportDocumentExpirationDate.value).getDate())) {
      this.userFormGroup.controls.passportDocumentExpirationDate.setValue(undefined);
    }

    const {
      notifications,
      language,
      identityDocumentExpirationDate,
      passportDocumentNumber,
      passportDocumentExpirationDate
    } = this.userFormGroup.value;

    return {
      identityDocumentExpirationDate: identityDocumentExpirationDate
        ? this.mTranslateService.formatDate(identityDocumentExpirationDate, 'yyyy-MM-dd')
        : undefined,
      defaultLocale: language || undefined,
      emailTravel: notifications.emailTravel || '',
      phoneNumberTravel: notifications.phoneNumberTravel || '',
      phoneNumberTravelPrefix: notifications.phoneNumberTravelPrefix || '',
      passportDocumentExpirationDate: passportDocumentExpirationDate
        ? this.mTranslateService.formatDate(passportDocumentExpirationDate, 'yyyy-MM-dd')
        : undefined,
      passportDocumentNumber: passportDocumentNumber || '',
      departmentId: this.user?.departmentId,
      societyId: this.user?.society?.id
    };
  }

  private getPrefix = (prefix: string | undefined): string => (prefix ? `+${parseInt(prefix, 10)}` : '');

  private initForm(): void {
    const notificationsGroup = this.fb.group({
      emailTravel: [this.user.emailTravel, [Validators.email, Validators.required]],
      phoneNumberTravel: [this.user.phoneNumberTravel, WhiteSpaceValidator.minValidCharLength(3)],
      phoneNumberTravelPrefix: [this.user.phoneNumberTravelPrefix]
    });

    this.userFormGroup = this.fb.group({
      language: [this.user.defaultLocale],
      notifications: notificationsGroup,
      identityDocumentExpirationDate: [
        { value: this.user.identityDocumentExpirationDate, disabled: !this.user.identityDocumentNumber }
      ],
      passportDocumentNumber: [this.user.passportDocumentNumber, [Validators.maxLength(50)]],
      passportDocumentExpirationDate: this.user.passportDocumentExpirationDate
    });
  }

  private setDepartmentName(): void {
    this.globalCacheService.departments.subscribe((departments) => {
      this.departmentName =
        departments.find((department) => department.id === this.user.departmentId)?.description || '';
    });
  }
}

// IMPORTANT: Constants in this file are defining the immutable configuration of the application.
// This values do not change with the environment.
// They always have the same value regardless of the environment in which the application is running.

import { AppConfig } from './app-config.interface';
import { RolesEnum } from './shared/consts/roles.const';

export const APP_CONFIG: AppConfig = {
  appName: 'portalviajes',
  language: {
    availableLanguages: ['es', 'pt', 'en'],
    defaultLanguage: 'es'
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  rol_default: RolesEnum.ROLE_EMPTY
};

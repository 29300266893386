import { Directive, Input, OnChanges, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@shared/authorization/auth.service';
import { CURRENT_ROLES } from 'app/shared/consts/roles.const';
import { ConditionInterface } from 'app/shared/interfaces/authorization.interface';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[profiling]'
})
export class ProfilingDirective implements OnChanges, OnDestroy {
  @Input() profiling: string[] | ConditionInterface[] | undefined;
  private subscription: Subscription[] = [];
  private isVisible: boolean = false;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<string[]>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnChanges(): void {
    this.validateAccess();
  }

  ngOnDestroy() {
    this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private validateAccess(): void {
    this.subscription.push(
      CURRENT_ROLES.subscribe((currentRoles) => {
        setTimeout(() => {
          this.profiling = this.profiling ? this.profiling : currentRoles;
          this.checkAuthorization();
        }, 0);
      })
    );
  }

  private checkAuthorization() {
    const profiling = JSON.parse(JSON.stringify(this.profiling));

    if (!this.isVisible && this.authService.hasRole(profiling)) {
      this.isVisible = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.isVisible && !this.authService.hasRole(profiling)) {
      this.isVisible = false;
      this.viewContainer.clear();
    }
  }
}

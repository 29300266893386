import { Injectable } from '@angular/core';

import { CancellationReasonResourceRequestModel } from '../../models/cancelation-reason/cancellation-reason-resource-request.model';
import { CancellationReasonsRepository } from './cancellation-reasons.repository';

@Injectable({
  providedIn: 'root'
})
export class CancellationReasonsService {
  constructor(private cancellationReasonsRepository: CancellationReasonsRepository) {}

  getCancellationReasons(firstPage?: number, pageSize?: number) {
    return this.cancellationReasonsRepository.getCancellationReasons(firstPage, pageSize);
  }

  createCancellationReason(data: CancellationReasonResourceRequestModel) {
    return this.cancellationReasonsRepository.createCancellationReason(data);
  }

  getCancellationReason(cancellationReasonId: number) {
    return this.cancellationReasonsRepository.getCancellationReason(cancellationReasonId);
  }

  fullUpdateCancellationReason(cancellationReasonId: number, data: CancellationReasonResourceRequestModel) {
    return this.cancellationReasonsRepository.fullUpdateCancellationReason(cancellationReasonId, data);
  }

  deleteCancellationReasons(cancellationReasonId: number) {
    return this.cancellationReasonsRepository.deleteCancellationReasons(cancellationReasonId);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { APP_CONFIG } from '@constants';
import { ProfilingDirectiveModule } from '@directives/profiling/profiling.directive.module';
import { AdministrationPageRoutingModule } from '@pages/administration/administration-page-routing.module';

import { MTranslateModule } from '@mercadona/core/translate';

import { MercadonaModule } from '../../mercadona.module';
import { AdministrationPageComponent } from './administration-page.component';
import { CardListSmartModule } from './components/card-list-smart/card-list-smart.module';

/**
 * AdministrationPageModule
 */
@NgModule({
  declarations: [AdministrationPageComponent],
  imports: [
    CommonModule,
    ProfilingDirectiveModule,
    AdministrationPageRoutingModule,
    MTranslateModule.forRoot(APP_CONFIG.language),
    MercadonaModule,
    CardListSmartModule
  ]
})
export class AdministrationPageModule {}

import { Injectable } from '@angular/core';

import { SocietiesRepository } from './societies.repository';

@Injectable({
  providedIn: 'root'
})
export class SocietiesService {
  constructor(private societiesRepository: SocietiesRepository) {}

  getSocieties(params?: { description?: string; pageSize?: number; firstPage?: number }) {
    return this.societiesRepository.getSocieties(params);
  }
}

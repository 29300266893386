import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProfilingDirective } from './profiling.directive';

/**
 * ComponentsSharedModule
 */
@NgModule({
  declarations: [ProfilingDirective],
  imports: [CommonModule],
  exports: [ProfilingDirective]
})
export class ProfilingDirectiveModule {}

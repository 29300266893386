import { PersonResourceCollectionInterface } from '../../interfaces/person-resource/person-resource-collection.interface';
import { PersonResourceModel } from './person-resource.model';

/**
 * PersonResourceCollectionModel
 */
export class PersonResourceCollectionModel {
  persons?: PersonResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: PersonResourceCollectionInterface) {
    this.persons = data.persons ? data.persons.map((person) => new PersonResourceModel(person)) : [];
  }
}

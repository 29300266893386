import { MyBookingsResourceCollectionInterface } from '@pages/g200/pages/bookings/interface/my-bookings-resource-collection.interface';
import { MyBookingsResourceModel } from '@pages/g200/pages/bookings/models/my-bookings-resource.model';

/**
 * MyBookingsResourceCollectionModel
 */
export class MyBookingsResourceCollectionModel {
  myBookings?: MyBookingsResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: MyBookingsResourceCollectionInterface) {
    this.myBookings = data.myBookings ? data.myBookings.map((booking) => new MyBookingsResourceModel(booking)) : [];
  }
}

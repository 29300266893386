import { BehaviorSubject } from 'rxjs';

import { CURRENT_ROLES, ROLES } from '../consts/roles.const';

export class AuthorizationClass {
  public roles = ROLES;
  public currentRoles = new BehaviorSubject<string[]>([]);

  constructor() {
    CURRENT_ROLES.subscribe(this.authorization.bind(this));
  }

  authorization(currentRoles: string[]) {
    this.currentRoles.next(currentRoles);
  }
}

import { MyBookingsResourceCollectionResponseInterface } from '@pages/g200/pages/bookings/interface/my-bookings-resource-collection-response.interface';
import { MyBookingsResourceCollectionModel } from '@pages/g200/pages/bookings/models/my-bookings-resource-collection.model';
import { PaginationModel } from '@shared/models/pagination.model';

/**
 * MyBookingsResourceCollectionResponseModel
 */
export class MyBookingsResourceCollectionResponseModel {
  data: MyBookingsResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: MyBookingsResourceCollectionResponseInterface) {
    this.data = new MyBookingsResourceCollectionModel(data.data);
    this.pagination = new PaginationModel(data.pagination);
  }
}

import { Component } from '@angular/core';
import { timer } from 'rxjs';

import { AppManagerService, IActionsItem, IAlertsItem, IGoBackItem } from '@services/app-manager/app-manager.service';

@Component({
  selector: 'app-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss']
})
export class HeaderTitleComponent {
  alerts: IAlertsItem[] = [];
  actions: IActionsItem[] = [];
  title: string = '';
  goBack: IGoBackItem | undefined = undefined;

  constructor(private appManagerService: AppManagerService) {
    this.getTitle();
    this.getActions();
    this.getAlerts();
    this.getGoBack();
  }

  trackByIndexAlert(index: number, alert: IAlertsItem) {
    return alert.title + index;
  }

  private getTitle() {
    this.appManagerService.headerTitleSubject.subscribe((title) => {
      this.title = title;
    });
  }

  private getActions() {
    this.appManagerService.headerActionsSubject.subscribe((actions) => {
      this.actions = actions;
    });
  }

  private getAlerts() {
    this.appManagerService.headerAlertsSubject.subscribe((alerts) => {
      this.alerts = alerts;

      const alertVisible = alerts.findIndex((alert) => alert.visible);
      if (alertVisible !== -1) {
        this.#hideAlert(alertVisible);
      }
    });
  }

  private getGoBack() {
    this.appManagerService.headerGoBackSubject.subscribe((goBack) => {
      this.goBack = goBack;
    });
  }

  #hideAlert(index: number, time: number = 2500) {
    timer(time).subscribe(() => {
      if (!this.alerts[index]) {
        return;
      }
      this.alerts[index].visible = false;
    });
  }
}

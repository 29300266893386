import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { ConditionInterface } from '@interfaces/authorization.interface';
import { AppManagerService } from '@services/app-manager/app-manager.service';

import { MAuthTokenLegacyService, MToken } from '@mercadona/core/auth-token-legacy';

import { MENU, MenuInterface } from '../consts/menu.const';
import { CURRENT_ROLES } from '../consts/roles.const';
import { UNAUTHORIZED_ROUTE } from '../consts/routes.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn$: Observable<boolean> = this.mAuthTokenService.isAuthenticated$;
  token$: Observable<MToken> = this.mAuthTokenService.getToken(environment.configToken.audience);

  constructor(
    private mAuthTokenService: MAuthTokenLegacyService,
    private appManagerService: AppManagerService,
    private router: Router
  ) {}

  checkAuth() {
    this.isLoggedIn$
      .pipe(
        switchMap((isLoggedIn) => {
          if (isLoggedIn) {
            return this.token$;
          }
          return of(null);
        }),
        tap((token) => {
          if (token) {
            this.updateRoles(token);

            if (!CURRENT_ROLES.getValue().length) {
              this.appManagerService.displayLogoutDialogSubject.next(true);
            } else {
              this.appManagerService.init(token);
              this.appManagerService.menuSubject.next(MENU.filter(this.filterMenu.bind(this)));
              this.appManagerService.displayLogoutDialogSubject.next(false);
              if (this.router.url === '/' + UNAUTHORIZED_ROUTE) {
                this.router.navigate(['']);
              }
            }
          }
        })
      )
      .subscribe();
  }

  updateRoles = (token: MToken) => {
    const groups = token?.operationGroups;
    CURRENT_ROLES.next(groups.filter(this.filterGroup));
  };

  hasRole(roles: string[] | ConditionInterface[]) {
    return Boolean(roles.findIndex(this.findIndexRoles) !== -1 || roles.findIndex(this.findIndexObjRoles) !== -1);
  }

  filterGroup = (r: string) => r !== '' && r.includes('ROLE_');

  findIndexObjRoles(rls: string | ConditionInterface) {
    return typeof rls !== 'string' && CURRENT_ROLES.getValue().includes(rls.rol) && rls.condition;
  }

  findIndexRoles = (rls: string | ConditionInterface) =>
    typeof rls === 'string' && CURRENT_ROLES.getValue().includes(rls);

  filterMenu(menu: MenuInterface) {
    if (menu.child) {
      menu.child = menu.child.filter(this.filterMenu.bind(this));
    }
    return this.hasRole(menu.roles);
  }
}

/**
 * CancellationReasonResourceModel
 */
import { CancellationReasonResourceInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource.interface';

export class CancellationReasonResourceModel {
  id?: number;
  code?: string;
  name?: string;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: CancellationReasonResourceInterface) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
  }
}

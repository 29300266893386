import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PersonResourceRequestModel } from '@models/person-resource/person-resource-request.model';
import { PersonSimpleResourceRequestModel } from '@models/person-resource/person-simple-resource-request.model';
import { UserFilterInterface } from '@pages/administration/pages/user-management/interfaces/user-filter.interface';

import { PersonsRepository } from './persons.repository';

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  userFilterParams: BehaviorSubject<UserFilterInterface> = new BehaviorSubject<UserFilterInterface>({});
  resetUserFilterForm = new EventEmitter<void>();
  applyUserFilterForm = new EventEmitter<void>();

  constructor(private personsRepository: PersonsRepository) {}

  resetUserFilters() {
    this.resetUserFilterForm.emit();
  }

  applyUserFilter() {
    this.applyUserFilterForm.emit();
  }

  getPersons(params?: {
    search?: string;
    typeOfPersonIds?: string;
    societyId?: number;
    departmentId?: number;
    isUpdatedExternally?: boolean;
    isManually?: boolean;
    firstPage?: number;
    pageSize?: number;
  }) {
    return this.personsRepository.getPersons(params);
  }

  createPerson(data: PersonResourceRequestModel) {
    return this.personsRepository.createPerson(data);
  }

  getPerson(personId: number) {
    return this.personsRepository.getPerson(personId);
  }

  updatePerson(personId: number, data: PersonSimpleResourceRequestModel) {
    return this.personsRepository.updatePerson(personId, data);
  }

  deletePerson(personId: number) {
    return this.personsRepository.deletePerson(personId);
  }
}

import { PersonResourceCollectionResponseInterface } from '../../interfaces/person-resource/person-resource-collection-response.interface';
import { PaginationModel } from '../pagination.model';
import { PersonResourceCollectionModel } from './person-resource-collection.model';

/**
 * PersonResourceCollectionResponseModel
 */
export class PersonResourceCollectionResponseModel {
  data: PersonResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: PersonResourceCollectionResponseInterface) {
    this.data = new PersonResourceCollectionModel(data.data);
    this.pagination = new PaginationModel(data.pagination);
  }
}

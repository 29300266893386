import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PersonResourceResponseModel } from '@models/person-resource/person-resource-response.model';
import { GlobalCacheService } from '@services/global-cache/global-cache.service';
import { SessionService } from '@services/session/session.service';
import { UsersService } from '@services/users/users.service';
import { MenuInterface } from '@shared/consts/menu.const';
import { RolesEnum } from '@shared/consts/roles.const';

import { MAlertColor } from '@mercadona/components/alert';
import { MButtonColor, MButtonType } from '@mercadona/components/button';
import { MToken } from '@mercadona/core/auth-token-legacy';
import { MLocalStorageService } from '@mercadona/core/storage';
import { MTranslateService } from '@mercadona/core/translate';
import { MIconInterface } from '@mercadona/icons';
import { MIconType } from '@mercadona/icons/svg';

import { IMAccessToken } from '../../../app.component';

export interface IActionsItem {
  text: string;
  icon?: MIconInterface;
  badge?: boolean;
  type: MButtonType;
  color: MButtonColor;
  roles?: RolesEnum[];
  action: () => void;
  disabled?: boolean;
}

export interface IAlertsItem {
  title: string;
  description: string;
  icon: MIconType;
  color: MAlertColor;
  visible: boolean;
  closeable: boolean;
  action?: () => void;
  actionText?: string;
}

export interface IGoBackItem {
  title: string;
  action: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class AppManagerService {
  user?: PersonResourceResponseModel;
  public headerActionsSubject = new BehaviorSubject<IActionsItem[]>([]);
  public headerAlertsSubject = new BehaviorSubject<IAlertsItem[]>([]);
  public headerTitleSubject = new BehaviorSubject<string>('');
  public noticesAlertSubject = new BehaviorSubject<boolean>(false);
  public g200NoticeAlertSubject = new BehaviorSubject<boolean>(false);
  public headerGoBackSubject = new BehaviorSubject<IGoBackItem | undefined>(undefined);
  public displayG200Subject = new BehaviorSubject<boolean>(true);
  public displayLogoutDialogSubject = new BehaviorSubject<boolean>(false);
  public menuSubject = new BehaviorSubject<MenuInterface[]>([]);

  constructor(
    private usersService: UsersService,
    private mTranslateService: MTranslateService,
    private sessionService: SessionService,
    private mLocalStorage: MLocalStorageService,
    private globalCacheService: GlobalCacheService
  ) {}

  init(token: MToken) {
    this.usersService.getUserInfo().subscribe((response) => {
      this.user = response;
      this.globalCacheService.setUser(response);
      this.#displayButton(this.user.data?.isRetailCompany);
    });
    this.setSessionStorage(token);
    this.setDefaultLanguage(token);
    this.globalCacheService.initServices();
  }

  updateHeaderTitle(title: string) {
    this.headerActionsSubject.next([]);
    this.headerAlertsSubject.next([]);
    this.headerGoBackSubject.next(undefined);
    this.headerTitleSubject.next(title);
  }

  updateHeaderActions(actions: IActionsItem[]) {
    this.headerActionsSubject.next(actions);
  }

  updateHeaderAlerts(alerts: IAlertsItem[]) {
    this.headerAlertsSubject.next(alerts);
  }

  updateHeaderGoBack(goBack: IGoBackItem | undefined) {
    this.headerGoBackSubject.next(goBack);
  }

  updateNoticesAlert(viewed: boolean) {
    this.noticesAlertSubject.next(viewed);
  }

  getNoticesAlertViewed(): boolean {
    return this.noticesAlertSubject.value;
  }

  updateG200NoticeAlert(viewed: boolean) {
    this.g200NoticeAlertSubject.next(viewed);
  }

  getG200NoticeAlertViewed(): boolean {
    return this.g200NoticeAlertSubject.value;
  }

  #displayButton(retailed?: boolean) {
    if (!retailed) {
      this.displayG200Subject.next(false);
    }
  }

  private setDefaultLanguage(token: MToken) {
    const currentLang = this.mLocalStorage.get('language');
    if (!currentLang) {
      const userLanguage = (token.accessTokenDecoded as unknown as IMAccessToken).preferredlanguage ?? 'es-ES';
      const userLanguageReplace = userLanguage.replace('_', '-');
      this.mLocalStorage.set('language', userLanguageReplace);
    }
    this.mTranslateService.setActiveLang(this.getStoredLang());
  }

  private setSessionStorage(token?: MToken) {
    if (token) {
      this.sessionService.setSessionStorage(JSON.stringify(token));
    }
  }

  private getStoredLang(): string {
    return this.mLocalStorage.get('language') ?? this.mTranslateService.getActiveLocale().substr(0, 2);
  }
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { NoticeResourceModel } from '@pages/administration/models/notices/notice-resource.model';

@Injectable({
  providedIn: 'root'
})
export class HeaderNoticeAlertService {
  private notice = new Subject<NoticeResourceModel | undefined>();
  notice$: Observable<NoticeResourceModel | undefined> = this.notice.asObservable();

  constructor() {}

  setHeaderNotice(notice: NoticeResourceModel | undefined) {
    this.notice.next(notice);
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SocietyResourceCollectionResponseInterface } from '../../interfaces/society-resource/society-resource-collection-response.interface';
import { SocietyResourceCollectionResponseModel } from '../../models/society-resource/society-resource-collection-response.model';
import { Repository } from '../abstract.repository';
/**
 * PlacesService
 */
@Injectable({
  providedIn: 'root'
})
export class SocietiesRepository extends Repository {
  baseUrl = `${this.basePath}/societies`;

  constructor(injector: Injector) {
    super(injector);
  }

  getSocieties(params?: {
    description?: string;
    pageSize?: number;
    firstPage?: number;
  }): Observable<SocietyResourceCollectionResponseModel> {
    return this.doRequest<SocietyResourceCollectionResponseInterface>('get', `${this.baseUrl}`, params).pipe(
      map((societies) => new SocietyResourceCollectionResponseModel(societies))
    );
  }
}

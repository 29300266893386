import { CancellationReasonResourceCollectionResponseInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource-collection-response.interface';
import { PaginationModel } from '../pagination.model';
import { CancellationReasonResourceCollectionModel } from './cancellation-reason-resource-collection.model';

/**
 * CancellationReasonResourceCollectionResponseModel
 */
export class CancellationReasonResourceCollectionResponseModel {
  data: CancellationReasonResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: CancellationReasonResourceCollectionResponseInterface) {
    this.data = new CancellationReasonResourceCollectionModel(data.data);
    this.pagination = new PaginationModel(data.pagination);
  }
}

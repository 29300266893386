import { Component, Input } from '@angular/core';

import { IActionsItem } from '@services/app-manager/app-manager.service';

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss']
})
export class HeaderActionsComponent {
  @Input() actions: IActionsItem[] = [];

  trackByIndexAction(index: number, action: IActionsItem) {
    return action.text + index;
  }
}

import { TypeOfPersonResourceCollectionResponseInterface } from '../../interfaces/type-of-person/type-of-person-resource-collection-response.interface';
import { PaginationModel } from '../pagination.model';
import { TypeOfPersonResourceCollectionModel } from './type-of-person-resource-collection.model';

/**
 * TypeOfPersonResourceCollectionResponseModel
 */
export class TypeOfPersonResourceCollectionResponseModel {
  data: TypeOfPersonResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: TypeOfPersonResourceCollectionResponseInterface) {
    this.data = new TypeOfPersonResourceCollectionModel(data.data);
    this.pagination = new PaginationModel(data.pagination);
  }
}

import { Injectable } from '@angular/core';

import { AirplanesRepository } from '@pages/g200/services/airplanes/airplanes.repository';

import { AirplaneResourceRequestModel } from '../../../../shared/models/airplane/airplane-resource-request.model';

@Injectable({
  providedIn: 'root'
})
export class AirplanesService {
  constructor(private airplanesRepository: AirplanesRepository) {}

  getAirplanes(firstPage?: number, pageSize?: number) {
    return this.airplanesRepository.getAirplanes(firstPage, pageSize);
  }

  createAirplane(data: AirplaneResourceRequestModel) {
    return this.airplanesRepository.createAirplane(data);
  }

  getAirplane(airplaneId: number) {
    return this.airplanesRepository.getAirplane(airplaneId);
  }

  fullUpdateAirplane(airplaneId: number, data: AirplaneResourceRequestModel) {
    return this.airplanesRepository.fullUpdateAirplane(airplaneId, data);
  }

  deleteAirplane(airplaneId: number) {
    return this.airplanesRepository.deleteAirplane(airplaneId);
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AirplaneResourceCollectionResponseInterface } from '../../../../shared/interfaces/airplane/airplane-resource-collection-response.interface';
import { AirplaneResourceResponseInterface } from '../../../../shared/interfaces/airplane/airplane-resource-response.interface';
import { AirplaneResourceInterface } from '../../../../shared/interfaces/airplane/airplane-resource.interface';
import { AirplaneResourceCollectionResponseModel } from '../../../../shared/models/airplane/airplane-resource-collection-response.model';
import { AirplaneResourceRequestModel } from '../../../../shared/models/airplane/airplane-resource-request.model';
import { AirplaneResourceResponseModel } from '../../../../shared/models/airplane/airplane-resource-response.model';
import { AirplaneResourceModel } from '../../../../shared/models/airplane/airplane-resource.model';
import { Repository } from '../../../../shared/services/abstract.repository';

@Injectable({
  providedIn: 'root'
})
export class AirplanesRepository extends Repository {
  baseUrl = `${this.basePath}/airplanes`;

  constructor(injector: Injector) {
    super(injector);
  }

  getAirplanes(firstPage?: number, pageSize?: number): Observable<AirplaneResourceCollectionResponseModel> {
    return this.doRequest<AirplaneResourceCollectionResponseInterface>('get', `${this.baseUrl}`, {
      firstPage,
      pageSize
    }).pipe(map((airplane) => new AirplaneResourceCollectionResponseModel(airplane)));
  }

  createAirplane(data: AirplaneResourceRequestModel): Observable<AirplaneResourceModel> {
    return this.doRequest<AirplaneResourceInterface>('post', `${this.baseUrl}`, undefined, { body: data }).pipe(
      map((airplane) => new AirplaneResourceModel(airplane))
    );
  }

  getAirplane(airplaneId: number): Observable<AirplaneResourceResponseModel> {
    return this.doRequest<AirplaneResourceResponseInterface>('get', `${this.baseUrl}/${airplaneId}`).pipe(
      map((airplane) => new AirplaneResourceResponseModel(airplane))
    );
  }

  fullUpdateAirplane(airplaneId: number, data: AirplaneResourceRequestModel): Observable<AirplaneResourceModel> {
    return this.doRequest<AirplaneResourceInterface>('put', `${this.baseUrl}/${airplaneId}`, undefined, {
      body: data
    }).pipe(map((airplane) => new AirplaneResourceModel(airplane)));
  }

  deleteAirplane(airplaneId: number): Observable<void> {
    return this.doRequest<void>('delete', `${this.baseUrl}/${airplaneId}`);
  }
}

import { Injectable } from '@angular/core';

import { TypesOfPersonsRepository } from './types-of-persons.repository';

@Injectable({
  providedIn: 'root'
})
export class TypesOfPersonsService {
  constructor(private typesOfPersonsRepository: TypesOfPersonsRepository) {}

  getTypesOfPersons(params?: { isCrew?: boolean; firstPage?: number; pageSize?: number; isDriver?: boolean }) {
    return this.typesOfPersonsRepository.getTypesOfPersons(params);
  }
}

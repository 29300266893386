import { Injectable } from '@angular/core';

import { MSnackbarConfig, MSnackbarService } from '@mercadona/components/snackbar';
import { MTranslateService } from '@mercadona/core/translate';

/**
 * ToastService
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private defaultSuccessMsg = 'COMMON.SAVE_SUCCESS';

  constructor(private mTranslateService: MTranslateService, private mSnackbarService: MSnackbarService) {}

  openSuccess(message?: string, translate: boolean = true) {
    const options: MSnackbarConfig = {
      message: !message || translate ? this.mTranslateService.translate(message || this.defaultSuccessMsg) : message,
      action: this.mTranslateService.translate('COMMON.CLOSE'),
      duration: 3000
    };
    this.mSnackbarService.create(options);
  }
}

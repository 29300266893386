import { DepartmentResourceCollectionInterface } from '../../interfaces/departament-resource/department-resource-collection.interface';
import { DepartmentResourceModel } from './department-resource.model';

/**
 * DepartmentResourceCollectionModel
 */
export class DepartmentResourceCollectionModel {
  departments?: DepartmentResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: DepartmentResourceCollectionInterface) {
    this.departments = data.departments
      ? data.departments.map((department) => new DepartmentResourceModel(department))
      : [];
  }
}

/**
 * PaginationModel
 */
import { PaginationInterface } from '../interfaces/pagination.interface';

export class PaginationModel {
  requestedPage: number;
  requestedSize: number;
  retrievedResults: number;
  totalResults: number;
  nextPage: string;
  previousPage: string;

  constructor(data: PaginationInterface) {
    this.requestedPage = data.requestedPage;
    this.requestedSize = data.requestedSize;
    this.retrievedResults = data.retrievedResults;
    this.totalResults = data.totalResults;
    this.nextPage = data.nextPage;
    this.previousPage = data.previousPage;
  }
}

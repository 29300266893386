import { TypeOfPersonResourceCollectionInterface } from '../../interfaces/type-of-person/type-of-person-resource-collection.interface';
import { TypeOfPersonResourceModel } from './type-of-person-resource.model';

/**
 * TypeOfPersonResourceCollectionModel
 */
export class TypeOfPersonResourceCollectionModel {
  typesOfPersons?: TypeOfPersonResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: TypeOfPersonResourceCollectionInterface) {
    this.typesOfPersons = data.typesOfPersons
      ? data.typesOfPersons.map((type) => new TypeOfPersonResourceModel(type))
      : [];
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CancellationReasonResourceCollectionResponseInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource-collection-response.interface';
import { CancellationReasonResourceResponseInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource-response.interface';
import { CancellationReasonResourceInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource.interface';
import { CancellationReasonResourceCollectionResponseModel } from '../../models/cancelation-reason/cancellation-reason-resource-collection-response.model';
import { CancellationReasonResourceRequestModel } from '../../models/cancelation-reason/cancellation-reason-resource-request.model';
import { CancellationReasonResourceResponseModel } from '../../models/cancelation-reason/cancellation-reason-resource-response.model';
import { CancellationReasonResourceModel } from '../../models/cancelation-reason/cancellation-reason-resource.model';
import { Repository } from '../abstract.repository';

@Injectable({
  providedIn: 'root'
})
export class CancellationReasonsRepository extends Repository {
  baseUrl = `${this.basePath}/cancellation-reasons`;

  constructor(injector: Injector) {
    super(injector);
  }

  getCancellationReasons(
    firstPage?: number,
    pageSize?: number
  ): Observable<CancellationReasonResourceCollectionResponseModel> {
    return this.doRequest<CancellationReasonResourceCollectionResponseInterface>('get', `${this.baseUrl}`, {
      firstPage,
      pageSize
    }).pipe(map((reasons) => new CancellationReasonResourceCollectionResponseModel(reasons)));
  }

  createCancellationReason(data: CancellationReasonResourceRequestModel): Observable<CancellationReasonResourceModel> {
    return this.doRequest<CancellationReasonResourceInterface>('post', `${this.baseUrl}`, undefined, {
      body: data
    }).pipe(map((reason) => new CancellationReasonResourceModel(reason)));
  }

  getCancellationReason(cancellationReasonId: number): Observable<CancellationReasonResourceResponseModel> {
    return this.doRequest<CancellationReasonResourceResponseInterface>(
      'get',
      `${this.baseUrl}/${cancellationReasonId}`
    ).pipe(map((reason) => new CancellationReasonResourceResponseModel(reason)));
  }

  fullUpdateCancellationReason(
    cancellationReasonId: number,
    data: CancellationReasonResourceRequestModel
  ): Observable<CancellationReasonResourceModel> {
    return this.doRequest<CancellationReasonResourceInterface>(
      'put',
      `${this.baseUrl}/${cancellationReasonId}`,
      undefined,
      { body: data }
    ).pipe(map((reason) => new CancellationReasonResourceModel(reason)));
  }

  deleteCancellationReasons(cancellationReasonId: number): Observable<void> {
    return this.doRequest<void>('delete', `${this.baseUrl}/${cancellationReasonId}`);
  }
}

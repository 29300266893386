import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { SESSION_STORAGE } from 'app/shared/consts/session.const';

import { MToken } from '@mercadona/core/auth';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  sessionStorage: string = SESSION_STORAGE;
  session = new BehaviorSubject<MToken | null>(null);

  setSessionStorage(session: string) {
    this.session.next(JSON.parse(session));
    sessionStorage.setItem(this.sessionStorage, session);
  }

  getSessionStorage(): MToken {
    const session = sessionStorage.getItem(this.sessionStorage);
    return session ? JSON.parse(session) : {};
  }
  getTokenSession(): string {
    const session = this.getSessionStorage();
    return session.accessToken ? session.accessToken : '';
  }

  removeTokenSession(): void {
    sessionStorage.removeItem(this.sessionStorage);
  }
}

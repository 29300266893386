<m-header
  showLogo
  hideDivider
  class="container-fluid"
  [ngStyle]="customHeaderColor ? { 'background-color': customHeaderColor } : { 'background-color': '' }">
  <button
    m-button
    m-header-prefix
    buttonType="icon"
    color="base"
    size="small"
    class="d-md-none"
    (click)="toggleLateralMenu()">
    <m-icon iconName="navigationMenuSvg"></m-icon>
  </button>
  <h1 m-header-title class="font-heading05-semibold cursor-pointer" routerLink="/">
    {{ ('BOOKINGS.TRAVEL_PORTAL' | mTranslate) + environmentName }}
  </h1>
  <m-header-actions>
    <a
      m-link
      color="secondary"
      target="_blank"
      class="d-none d-md-flex m-link-secondary"
      [m-context-menu-trigger-for]="languageMenu">
      {{ 'BOOKINGS.LANGUAGE' | mTranslate }}
      <m-icon iconName="arrowDown1Svg"></m-icon>
    </a>
    <m-context-menu #languageMenu>
      <m-context-menu-item [selected]="currentLanguage === languages[0]" (click)="setAppLanguage(0)">
        {{ 'LANGUAGES.SPANISH' | mTranslate }}
      </m-context-menu-item>
      <m-context-menu-item [selected]="currentLanguage === languages[1]" (click)="setAppLanguage(1)">
        {{ 'LANGUAGES.PORTUGUESE' | mTranslate }}
      </m-context-menu-item>
    </m-context-menu>

    <div class="d-inline-flex userbudget" [m-context-menu-trigger-for]="sessionMenu">
      <ng-container class="body02Semibold">
        {{ this.userNameInitials }}
      </ng-container>
    </div>
    <m-context-menu #sessionMenu>
      <m-context-menu-item disabled="true">
        <div>
          <span class="mb-1 font-body02-semibold user-name">{{ userName }}</span>
        </div>
        <div class="pt-1 pb-2">
          <span class="mb-1 font-body03-regular"> </span>
        </div>
      </m-context-menu-item>
      <hr class="context-menu-separator" />
      <m-context-menu-item sessionMenuLogoutItem routerLink="./user-profile">
        <m-icon iconName="singleNeutralBoldSvg"></m-icon>
        {{ 'USER_PROFILE.TITLE' | mTranslate }}
      </m-context-menu-item>
      <m-context-menu-item sessionMenuLogoutItem (click)="showLogoutDialog()">
        <m-icon iconName="logout1Svg"></m-icon>
        {{ 'COMMON.CLOSE_SESSION' | mTranslate }}
      </m-context-menu-item>
    </m-context-menu>
  </m-header-actions>
</m-header>
<ng-container>
  <div
    *ngIf="this.showInteractionShield"
    class="d-flex d-md-none interaction-shield"
    (click)="toggleLateralMenu()"></div>

  <app-menu class="d-none d-md-block" [isLateral]="false" [menu]="menu"></app-menu>

  <div [ngClass]="!showLateralMenu ? 'd-none' : 'd-block d-md-none col-10 col-sm-7 lateral-menu'">
    <div class="p-2 lateral-menu-header">
      <div>
        <m-icon iconName="navigationMenuSvg"></m-icon>
      </div>
      <div class="mx-2 lateral-menu-header-content">
        <span class="mb-1 font-body02-semibold">{{ userName }}</span>
        <span class="mb-1 font-body03-regular">{{ 'MENU.INFORMATION_LINE' | mTranslate }}</span>
      </div>
    </div>
    <app-menu [isLateral]="true" [menu]="menu" (itemSelectedEvent)="toggleLateralMenu()"></app-menu>
    <m-menu type="vertical">
      <m-menu-item>
        {{ 'BOOKINGS.LANGUAGE' | mTranslate }}
        <m-menu-group>
          <m-menu-item [selected]="currentLanguage === languages[0]" (click)="setAppLanguage(0)">
            {{ 'LANGUAGES.SPANISH' | mTranslate }}
          </m-menu-item>
          <m-menu-item [selected]="currentLanguage === languages[1]" (click)="setAppLanguage(1)">
            {{ 'LANGUAGES.PORTUGUESE' | mTranslate }}
          </m-menu-item>
        </m-menu-group>
      </m-menu-item>
      <m-menu-divider></m-menu-divider>

      <m-menu-item (click)="showLogoutDialog()">
        <m-icon iconName="logout1Svg"></m-icon>
        {{ 'COMMON.CLOSE_SESSION' | mTranslate }}
      </m-menu-item>
    </m-menu>
  </div>
</ng-container>

export interface CountryCode {
  label: string;
  codeDisplay: string;
  codeNum: string;
  country: string;
}

export const countryCodes: CountryCode[] = [
  {
    nameEn: 'Spain',
    nameEs: 'España',
    dialCode: '+34',
    code: 'ES'
  },
  {
    nameEn: 'Portugal',
    nameEs: 'Portugal',
    dialCode: '+351',
    code: 'PT'
  },
  {
    nameEn: 'Afghanistan',
    nameEs: 'Afganistán',
    dialCode: '+93',
    code: 'AF'
  },
  {
    nameEn: 'Albania',
    nameEs: 'Albania',
    dialCode: '+355',
    code: 'AL'
  },
  {
    nameEn: 'Algeria',
    nameEs: 'Argelia',
    dialCode: '+213',
    code: 'DZ'
  },
  {
    nameEn: 'AmericanSamoa',
    nameEs: 'Samoa Americana',
    dialCode: '+1684',
    code: 'AS'
  },
  {
    nameEn: 'Andorra',
    nameEs: 'Andorra',
    dialCode: '+376',
    code: 'AD'
  },
  {
    nameEn: 'Angola',
    nameEs: 'Angola',
    dialCode: '+244',
    code: 'AO'
  },
  {
    nameEn: 'Anguilla',
    nameEs: 'Anguilla',
    dialCode: '+1264',
    code: 'AI'
  },
  {
    nameEn: 'Antarctica',
    nameEs: 'Antártida',
    dialCode: '+672',
    code: 'AQ'
  },
  {
    nameEn: 'Antigua and Barbuda',
    nameEs: 'Antigua y Barbuda',
    dialCode: '+1268',
    code: 'AG'
  },
  {
    nameEn: 'Argentina',
    nameEs: 'Argentina',
    dialCode: '+54',
    code: 'AR'
  },
  {
    nameEn: 'Armenia',
    nameEs: 'Armenia',
    dialCode: '+374',
    code: 'AM'
  },
  {
    nameEn: 'Aruba',
    nameEs: 'Aruba',
    dialCode: '+297',
    code: 'AW'
  },
  {
    nameEn: 'Australia',
    nameEs: 'Australia',
    dialCode: '+61',
    code: 'AU'
  },
  {
    nameEn: 'Austria',
    nameEs: 'Austria',
    dialCode: '+43',
    code: 'AT'
  },
  {
    nameEn: 'Azerbaijan',
    nameEs: 'Azerbaiyán',
    dialCode: '+994',
    code: 'AZ'
  },
  {
    nameEn: 'Bahamas',
    nameEs: 'Bahamas',
    dialCode: '+1242',
    code: 'BS'
  },
  {
    nameEn: 'Bahrain',
    nameEs: 'Baréin',
    dialCode: '+973',
    code: 'BH'
  },
  {
    nameEn: 'Bangladesh',
    nameEs: 'Banglades',
    dialCode: '+880',
    code: 'BD'
  },
  {
    nameEn: 'Barbados',
    nameEs: 'Barbados',
    dialCode: '+1246',
    code: 'BB'
  },
  {
    nameEn: 'Belarus',
    nameEs: 'Bielorrusia',
    dialCode: '+375',
    code: 'BY'
  },
  {
    nameEn: 'Belgium',
    nameEs: 'Bélgica',
    dialCode: '+32',
    code: 'BE'
  },
  {
    nameEn: 'Belize',
    nameEs: 'Belice',
    dialCode: '+501',
    code: 'BZ'
  },
  {
    nameEn: 'Benin',
    nameEs: 'Benin',
    dialCode: '+229',
    code: 'BJ'
  },
  {
    nameEn: 'Bermuda',
    nameEs: 'Bermudas',
    dialCode: '+1441',
    code: 'BM'
  },
  {
    nameEn: 'Bhutan',
    nameEs: 'Butan',
    dialCode: '+975',
    code: 'BT'
  },
  {
    nameEn: 'Bolivia',
    nameEs: 'Bolivia',
    dialCode: '+591',
    code: 'BO'
  },
  {
    nameEn: 'Bosnia and Herzegovina',
    nameEs: 'Bosnia-Herzegovina',
    dialCode: '+387',
    code: 'BA'
  },
  {
    nameEn: 'Botswana',
    nameEs: 'Botsuana',
    dialCode: '+267',
    code: 'BW'
  },
  {
    nameEn: 'Brazil',
    nameEs: 'Brasil',
    dialCode: '+55',
    code: 'BR'
  },
  {
    nameEn: 'British Indian Ocean Territory',
    nameEs: 'Territorio Británico del Océano Índico',
    dialCode: '+246',
    code: 'IO'
  },
  {
    nameEn: 'Brunei Darussalam',
    nameEs: 'Brunei',
    dialCode: '+673',
    code: 'BN'
  },
  {
    nameEn: 'Bulgaria',
    nameEs: 'Bulgaria',
    dialCode: '+359',
    code: 'BG'
  },
  {
    nameEn: 'Burkina Faso',
    nameEs: 'Burkina Faso',
    dialCode: '+226',
    code: 'BF'
  },
  {
    nameEn: 'Burundi',
    nameEs: 'Burundi',
    dialCode: '+257',
    code: 'BI'
  },
  {
    nameEn: 'Cambodia',
    nameEs: 'Camboya',
    dialCode: '+855',
    code: 'KH'
  },
  {
    nameEn: 'Cameroon',
    nameEs: 'Camerún',
    dialCode: '+237',
    code: 'CM'
  },
  {
    nameEn: 'Canada',
    nameEs: 'Canadá',
    dialCode: '+1',
    code: 'CA'
  },
  {
    nameEn: 'Cape Verde',
    nameEs: 'Cabo Verde',
    dialCode: '+238',
    code: 'CV'
  },
  {
    nameEn: 'Cayman Islands',
    nameEs: 'Islas Caimán',
    dialCode: '+ 345',
    code: 'KY'
  },
  {
    nameEn: 'Central African Republic',
    nameEs: 'República Centroafricana',
    dialCode: '+236',
    code: 'CF'
  },
  {
    nameEn: 'Chad',
    nameEs: 'Chad',
    dialCode: '+235',
    code: 'TD'
  },
  {
    nameEn: 'Chile',
    nameEs: 'Chile',
    dialCode: '+56',
    code: 'CL'
  },
  {
    nameEn: 'China',
    nameEs: 'China',
    dialCode: '+86',
    code: 'CN'
  },
  {
    nameEn: 'Christmas Island',
    nameEs: 'Isla de Navidad',
    dialCode: '+61',
    code: 'CX'
  },
  {
    nameEn: 'Cocos (Keeling) Islands',
    nameEs: 'Islas Cocos',
    dialCode: '+61',
    code: 'CC'
  },
  {
    nameEn: 'Colombia',
    nameEs: 'Colombia',
    dialCode: '+57',
    code: 'CO'
  },
  {
    nameEn: 'Comoros',
    nameEs: 'Comoras',
    dialCode: '+269',
    code: 'KM'
  },
  {
    nameEn: 'Congo',
    nameEs: 'Congo',
    dialCode: '+242',
    code: 'CG'
  },
  {
    nameEn: 'Congo, The Democratic Republic of the',
    nameEs: 'República Democrática del Congo',
    dialCode: '+243',
    code: 'CD'
  },
  {
    nameEn: 'Cook Islands',
    nameEs: 'Islas Cook',
    dialCode: '+682',
    code: 'CK'
  },
  {
    nameEn: 'Costa Rica',
    nameEs: 'Costa Rica',
    dialCode: '+506',
    code: 'CR'
  },
  {
    nameEn: "Cote d'Ivoire",
    nameEs: 'Costa de Marfil',
    dialCode: '+225',
    code: 'CI'
  },
  {
    nameEn: 'Croatia',
    nameEs: 'Croacia',
    dialCode: '+385',
    code: 'HR'
  },
  {
    nameEn: 'Cuba',
    nameEs: 'Cuba',
    dialCode: '+53',
    code: 'CU'
  },
  {
    nameEn: 'Cyprus',
    nameEs: 'Chipre',
    dialCode: '+537',
    code: 'CY'
  },
  {
    nameEn: 'Czechia',
    nameEs: 'Chequia',
    dialCode: '+420',
    code: 'CZ'
  },
  {
    nameEn: 'Denmark',
    nameEs: 'Dinamarca',
    dialCode: '+45',
    code: 'DK'
  },
  {
    nameEn: 'Djibouti',
    nameEs: 'Yibuti',
    dialCode: '+253',
    code: 'DJ'
  },
  {
    nameEn: 'Dominica',
    nameEs: 'Dominica',
    dialCode: '+1767',
    code: 'DM'
  },
  {
    nameEn: 'Dominican Republic',
    nameEs: 'República Dominicana',
    dialCode: '+1849',
    code: 'DO'
  },
  {
    nameEn: 'Ecuador',
    nameEs: 'Ecuador',
    dialCode: '+593',
    code: 'EC'
  },
  {
    nameEn: 'Egypt',
    nameEs: 'Egipto',
    dialCode: '+20',
    code: 'EG'
  },
  {
    nameEn: 'El Salvador',
    nameEs: 'El Salvador',
    dialCode: '+503',
    code: 'SV'
  },
  {
    nameEn: 'Equatorial Guinea',
    nameEs: 'Guinea Ecuatorial',
    dialCode: '+240',
    code: 'GQ'
  },
  {
    nameEn: 'Eritrea',
    nameEs: 'Eritrea',
    dialCode: '+291',
    code: 'ER'
  },
  {
    nameEn: 'Estonia',
    nameEs: 'Estonia',
    dialCode: '+372',
    code: 'EE'
  },
  {
    nameEn: 'Ethiopia',
    nameEs: 'Etiopía',
    dialCode: '+251',
    code: 'ET'
  },
  {
    nameEn: 'Falkland Islands (Malvinas)',
    nameEs: 'Islas Malvinas',
    dialCode: '+500',
    code: 'FK'
  },
  {
    nameEn: 'Faroe Islands',
    nameEs: 'Islas Feroe',
    dialCode: '+298',
    code: 'FO'
  },
  {
    nameEn: 'Fiji',
    nameEs: 'Fiyi',
    dialCode: '+679',
    code: 'FJ'
  },
  {
    nameEn: 'Finland',
    nameEs: 'Finlandia',
    dialCode: '+358',
    code: 'FI'
  },
  {
    nameEn: 'France',
    nameEs: 'Francia',
    dialCode: '+33',
    code: 'FR'
  },
  {
    nameEn: 'French Guiana',
    nameEs: 'Guayana Francesa',
    dialCode: '+594',
    code: 'GF'
  },
  {
    nameEn: 'French Polynesia',
    nameEs: 'Polinesia Francesa',
    dialCode: '+689',
    code: 'PF'
  },
  {
    nameEn: 'Gabon',
    nameEs: 'Gabón',
    dialCode: '+241',
    code: 'GA'
  },
  {
    nameEn: 'Gambia',
    nameEs: 'Gambia',
    dialCode: '+220',
    code: 'GM'
  },
  {
    nameEn: 'Georgia',
    nameEs: 'Georgia',
    dialCode: '+995',
    code: 'GE'
  },
  {
    nameEn: 'Germany',
    nameEs: 'Alemania',
    dialCode: '+49',
    code: 'DE'
  },
  {
    nameEn: 'Ghana',
    nameEs: 'Ghana',
    dialCode: '+233',
    code: 'GH'
  },
  {
    nameEn: 'Gibraltar',
    nameEs: 'Gibraltar',
    dialCode: '+350',
    code: 'GI'
  },
  {
    nameEn: 'Greece',
    nameEs: 'Grecia',
    dialCode: '+30',
    code: 'GR'
  },
  {
    nameEn: 'Greenland',
    nameEs: 'Groenlandia',
    dialCode: '+299',
    code: 'GL'
  },
  {
    nameEn: 'Grenada',
    nameEs: 'Granada',
    dialCode: '+1473',
    code: 'GD'
  },
  {
    nameEn: 'Guadeloupe',
    nameEs: 'Guadalupe',
    dialCode: '+590',
    code: 'GP'
  },
  {
    nameEn: 'Guam',
    nameEs: 'Guam',
    dialCode: '+1671',
    code: 'GU'
  },
  {
    nameEn: 'Guatemala',
    nameEs: 'Guatemala',
    dialCode: '+502',
    code: 'GT'
  },
  {
    nameEn: 'Guernsey',
    nameEs: 'Guernsey',
    dialCode: '+44',
    code: 'GG'
  },
  {
    nameEn: 'Guinea',
    nameEs: 'Guinea',
    dialCode: '+224',
    code: 'GN'
  },
  {
    nameEn: 'Guinea-Bissau',
    nameEs: 'Guinea-Bisau',
    dialCode: '+245',
    code: 'GW'
  },
  {
    nameEn: 'Guyana',
    nameEs: 'Guyana',
    dialCode: '+595',
    code: 'GY'
  },
  {
    nameEn: 'Haiti',
    nameEs: 'Haití',
    dialCode: '+509',
    code: 'HT'
  },
  {
    nameEn: 'Holy See (Vatican City State)',
    nameEs: 'Ciudad del Vaticano',
    dialCode: '+379',
    code: 'VA'
  },
  {
    nameEn: 'Honduras',
    nameEs: 'Honduras',
    dialCode: '+504',
    code: 'HN'
  },
  {
    nameEn: 'Hong Kong',
    nameEs: 'Hong Kong',
    dialCode: '+852',
    code: 'HK'
  },
  {
    nameEn: 'Hungary',
    nameEs: 'Hungría',
    dialCode: '+36',
    code: 'HU'
  },
  {
    nameEn: 'Iceland',
    nameEs: 'Islandia',
    dialCode: '+354',
    code: 'IS'
  },
  {
    nameEn: 'India',
    nameEs: 'India',
    dialCode: '+91',
    code: 'IN'
  },
  {
    nameEn: 'Indonesia',
    nameEs: 'Indonesia',
    dialCode: '+62',
    code: 'ID'
  },
  {
    nameEn: 'Iran, Islamic Republic of',
    nameEs: 'Irán',
    dialCode: '+98',
    code: 'IR'
  },
  {
    nameEn: 'Iraq',
    nameEs: 'Iraq',
    dialCode: '+964',
    code: 'IQ'
  },
  {
    nameEn: 'Ireland',
    nameEs: 'Irlanda',
    dialCode: '+353',
    code: 'IE'
  },
  {
    nameEn: 'Isle of Man',
    nameEs: 'Isla de Man',
    dialCode: '+44',
    code: 'IM'
  },
  {
    nameEn: 'Israel',
    nameEs: 'Israel',
    dialCode: '+972',
    code: 'IL'
  },
  {
    nameEn: 'Italy',
    nameEs: 'Italia',
    dialCode: '+39',
    code: 'IT'
  },
  {
    nameEn: 'Jamaica',
    nameEs: 'Jamaica',
    dialCode: '+1876',
    code: 'JM'
  },
  {
    nameEn: 'Japan',
    nameEs: 'Japón',
    dialCode: '+81',
    code: 'JP'
  },
  {
    nameEn: 'Jersey',
    nameEs: 'Jersey',
    dialCode: '+44',
    code: 'JE'
  },
  {
    nameEn: 'Jordan',
    nameEs: 'Jordania',
    dialCode: '+962',
    code: 'JO'
  },
  {
    nameEn: 'Kazakhstan',
    nameEs: 'Kazajistán',
    dialCode: '+7',
    code: 'KZ'
  },
  {
    nameEn: 'Kenya',
    nameEs: 'Kenia',
    dialCode: '+254',
    code: 'KE'
  },
  {
    nameEn: 'Kiribati',
    nameEs: 'Kiribati',
    dialCode: '+686',
    code: 'KI'
  },
  {
    nameEn: "Korea, Democratic People's Republic of",
    nameEs: 'Corea del Norte',
    dialCode: '+850',
    code: 'KP'
  },
  {
    nameEn: 'Korea, Republic of',
    nameEs: 'Corea del Sur',
    dialCode: '+82',
    code: 'KR'
  },
  {
    nameEn: 'Kosovo',
    nameEs: 'Kosovo',
    dialCode: '+383',
    code: 'XK'
  },
  {
    nameEn: 'Kuwait',
    nameEs: 'Kuwait',
    dialCode: '+965',
    code: 'KW'
  },
  {
    nameEn: 'Kyrgyzstan',
    nameEs: 'Kirguistán',
    dialCode: '+996',
    code: 'KG'
  },
  {
    nameEn: "Lao People's Democratic Republic",
    nameEs: 'Laos',
    dialCode: '+856',
    code: 'LA'
  },
  {
    nameEn: 'Latvia',
    nameEs: 'Letonia',
    dialCode: '+371',
    code: 'LV'
  },
  {
    nameEn: 'Lebanon',
    nameEs: 'Líbano',
    dialCode: '+961',
    code: 'LB'
  },
  {
    nameEn: 'Lesotho',
    nameEs: 'Lesoto',
    dialCode: '+266',
    code: 'LS'
  },
  {
    nameEn: 'Liberia',
    nameEs: 'Liberia',
    dialCode: '+231',
    code: 'LR'
  },
  {
    nameEn: 'Libyan Arab Jamahiriya',
    nameEs: 'Libia',
    dialCode: '+218',
    code: 'LY'
  },
  {
    nameEn: 'Liechtenstein',
    nameEs: 'Liechtenstein',
    dialCode: '+423',
    code: 'LI'
  },
  {
    nameEn: 'Lithuania',
    nameEs: 'Lituania',
    dialCode: '+370',
    code: 'LT'
  },
  {
    nameEn: 'Luxembourg',
    nameEs: 'Luxemburgo',
    dialCode: '+352',
    code: 'LU'
  },
  {
    nameEn: 'Macao',
    nameEs: 'Macao',
    dialCode: '+853',
    code: 'MO'
  },
  {
    nameEn: 'Macedonia, The Former Yugoslav Republic of',
    nameEs: 'República de Macedonia',
    dialCode: '+389',
    code: 'MK'
  },
  {
    nameEn: 'Madagascar',
    nameEs: 'Madagascar',
    dialCode: '+261',
    code: 'MG'
  },
  {
    nameEn: 'Malawi',
    nameEs: 'Malaui',
    dialCode: '+265',
    code: 'MW'
  },
  {
    nameEn: 'Malaysia',
    nameEs: 'Malasia',
    dialCode: '+60',
    code: 'MY'
  },
  {
    nameEn: 'Maldives',
    nameEs: 'Maldivas',
    dialCode: '+960',
    code: 'MV'
  },
  {
    nameEn: 'Mali',
    nameEs: 'Malí',
    dialCode: '+223',
    code: 'ML'
  },
  {
    nameEn: 'Malta',
    nameEs: 'Malta',
    dialCode: '+356',
    code: 'MT'
  },
  {
    nameEn: 'Marshall Islands',
    nameEs: 'Islas Marshall',
    dialCode: '+692',
    code: 'MH'
  },
  {
    nameEn: 'Martinique',
    nameEs: 'Martinica',
    dialCode: '+596',
    code: 'MQ'
  },
  {
    nameEn: 'Mauritania',
    nameEs: 'Mauritania',
    dialCode: '+222',
    code: 'MR'
  },
  {
    nameEn: 'Mauritius',
    nameEs: 'Mauricio',
    dialCode: '+230',
    code: 'MU'
  },
  {
    nameEn: 'Mayotte',
    nameEs: 'Mayotte',
    dialCode: '+262',
    code: 'YT'
  },
  {
    nameEn: 'Mexico',
    nameEs: 'México',
    dialCode: '+52',
    code: 'MX'
  },
  {
    nameEn: 'Micronesia, Federated States of',
    nameEs: 'Estados Federados de Micronesia',
    dialCode: '+691',
    code: 'FM'
  },
  {
    nameEn: 'Moldova, Republic of',
    nameEs: 'Moldavia',
    dialCode: '+373',
    code: 'MD'
  },
  {
    nameEn: 'Monaco',
    nameEs: 'Monaco',
    dialCode: '+377',
    code: 'MC'
  },
  {
    nameEn: 'Mongolia',
    nameEs: 'Mongolia',
    dialCode: '+976',
    code: 'MN'
  },
  {
    nameEn: 'Montenegro',
    nameEs: 'Montenegro',
    dialCode: '+382',
    code: 'ME'
  },
  {
    nameEn: 'Montserrat',
    nameEs: 'Montserrat',
    dialCode: '+1664',
    code: 'MS'
  },
  {
    nameEn: 'Morocco',
    nameEs: 'Marruecos',
    dialCode: '+212',
    code: 'MA'
  },
  {
    nameEn: 'Mozambique',
    nameEs: 'Mozambique',
    dialCode: '+258',
    code: 'MZ'
  },
  {
    nameEn: 'Myanmar',
    nameEs: 'Birmania',
    dialCode: '+95',
    code: 'MM'
  },
  {
    nameEn: 'Namibia',
    nameEs: 'Namibia',
    dialCode: '+264',
    code: 'NA'
  },
  {
    nameEn: 'Nauru',
    nameEs: 'Nauru',
    dialCode: '+674',
    code: 'NR'
  },
  {
    nameEn: 'Nepal',
    nameEs: 'Nepal',
    dialCode: '+977',
    code: 'NP'
  },
  {
    nameEn: 'Netherlands',
    nameEs: 'Holanda',
    dialCode: '+31',
    code: 'NL'
  },
  {
    nameEn: 'Netherlands Antilles',
    nameEs: 'Antillas Holandesas',
    dialCode: '+599',
    code: 'AN'
  },
  {
    nameEn: 'New Caledonia',
    nameEs: 'Nueva Caledonia',
    dialCode: '+687',
    code: 'NC'
  },
  {
    nameEn: 'New Zealand',
    nameEs: 'Nueva Zelanda',
    dialCode: '+64',
    code: 'NZ'
  },
  {
    nameEn: 'Nicaragua',
    nameEs: 'Nicaragua',
    dialCode: '+505',
    code: 'NI'
  },
  {
    nameEn: 'Niger',
    nameEs: 'Niger',
    dialCode: '+227',
    code: 'NE'
  },
  {
    nameEn: 'Nigeria',
    nameEs: 'Nigeria',
    dialCode: '+234',
    code: 'NG'
  },
  {
    nameEn: 'Niue',
    nameEs: 'Niue',
    dialCode: '+683',
    code: 'NU'
  },
  {
    nameEn: 'NorfolkIsland',
    nameEs: 'IslaNorfolk',
    dialCode: '+672',
    code: 'NF'
  },
  {
    nameEn: 'NorthernMarianaIslands',
    nameEs: 'IslasMarianasdelNorte',
    dialCode: '+1670',
    code: 'MP'
  },
  {
    nameEn: 'Norway',
    nameEs: 'Noruega',
    dialCode: '+47',
    code: 'NO'
  },
  {
    nameEn: 'Oman',
    nameEs: 'Omán',
    dialCode: '+968',
    code: 'OM'
  },
  {
    nameEn: 'Pakistan',
    nameEs: 'Pakistán',
    dialCode: '+92',
    code: 'PK'
  },
  {
    nameEn: 'Palau',
    nameEs: 'Palaos',
    dialCode: '+680',
    code: 'PW'
  },
  {
    nameEn: 'Panama',
    nameEs: 'Panamá',
    dialCode: '+507',
    code: 'PA'
  },
  {
    nameEn: 'Papua New Guinea',
    nameEs: 'Papúa Nueva Guinea',
    dialCode: '+675',
    code: 'PG'
  },
  {
    nameEn: 'Paraguay',
    nameEs: 'Paraguay',
    dialCode: '+595',
    code: 'PY'
  },
  {
    nameEn: 'Peru',
    nameEs: 'Perú',
    dialCode: '+51',
    code: 'PE'
  },
  {
    nameEn: 'Philippines',
    nameEs: 'Filipinas',
    dialCode: '+63',
    code: 'PH'
  },
  {
    nameEn: 'Pitcairn',
    nameEs: 'Islas Pitcairn',
    dialCode: '+872',
    code: 'PN'
  },
  {
    nameEn: 'Poland',
    nameEs: 'Polonia',
    dialCode: '+48',
    code: 'PL'
  },

  {
    nameEn: 'Puerto Rico',
    nameEs: 'Puerto Rico',
    dialCode: '+1939',
    code: 'PR'
  },
  {
    nameEn: 'Qatar',
    nameEs: 'Qatar',
    dialCode: '+974',
    code: 'QA'
  },
  {
    nameEn: 'Romania',
    nameEs: 'Rumania',
    dialCode: '+40',
    code: 'RO'
  },
  {
    nameEn: 'Russia',
    nameEs: 'Rusia',
    dialCode: '+7',
    code: 'RU'
  },
  {
    nameEn: 'Rwanda',
    nameEs: 'Ruanda',
    dialCode: '+250',
    code: 'RW'
  },
  {
    nameEn: 'Réunion',
    nameEs: 'Reunion',
    dialCode: '+262',
    code: 'RE'
  },
  {
    nameEn: 'Saint Barthélemy',
    nameEs: 'San Bartolome',
    dialCode: '+590',
    code: 'BL'
  },
  {
    nameEn: 'Saint Helena, Ascension and Tristan Da Cunha',
    nameEs: 'Santa Elena, Ascensión y Tristán de Acuña',
    dialCode: '+290',
    code: 'SH'
  },
  {
    nameEn: 'Saint Kitts and Nevis',
    nameEs: 'San Cristóbal y Nieves',
    dialCode: '+1869',
    code: 'KN'
  },
  {
    nameEn: 'Saint Lucia',
    nameEs: 'Santa Lucía',
    dialCode: '+1758',
    code: 'LC'
  },
  {
    nameEn: 'Saint Martin',
    nameEs: 'Isla de San Martín',
    dialCode: '+590',
    code: 'MF'
  },
  {
    nameEn: 'Saint Pierre and Miquelon',
    nameEs: 'San Pedro y Miquelon',
    dialCode: '+508',
    code: 'PM'
  },
  {
    nameEn: 'Saint Vincent and the Grenadines',
    nameEs: 'San Vicente y las Granadinas',
    dialCode: '+1784',
    code: 'VC'
  },
  {
    nameEn: 'Samoa',
    nameEs: 'Samoa',
    dialCode: '+685',
    code: 'WS'
  },
  {
    nameEn: 'San Marino',
    nameEs: 'San Marino',
    dialCode: '+378',
    code: 'SM'
  },
  {
    nameEn: 'Sao Tome and Principe',
    nameEs: ' Santo Tomé y Príncipe',
    dialCode: '+239',
    code: 'ST'
  },
  {
    nameEn: 'Saudi Arabia',
    nameEs: 'Arabia Saudita',
    dialCode: '+966',
    code: 'SA'
  },
  {
    nameEn: 'Senegal',
    nameEs: 'Senegal',
    dialCode: '+221',
    code: 'SN'
  },
  {
    nameEn: 'Serbia',
    nameEs: 'Serbia',
    dialCode: '+381',
    code: 'RS'
  },
  {
    nameEn: 'Seychelles',
    nameEs: 'Seychelles',
    dialCode: '+248',
    code: 'SC'
  },
  {
    nameEn: 'Sierra Leone',
    nameEs: 'Sierra Leona',
    dialCode: '+232',
    code: 'SL'
  },
  {
    nameEn: 'Singapore',
    nameEs: 'Singapur',
    dialCode: '+65',
    code: 'SG'
  },
  {
    nameEn: 'Slovakia',
    nameEs: 'Eslovaquia',
    dialCode: '+421',
    code: 'SK'
  },
  {
    nameEn: 'Slovenia',
    nameEs: 'Eslovenia',
    dialCode: '+386',
    code: 'SI'
  },
  {
    nameEn: 'Solomon Islands',
    nameEs: 'Islas Salomón',
    dialCode: '+677',
    code: 'SB'
  },
  {
    nameEn: 'Somalia',
    nameEs: 'Somalia',
    dialCode: '+252',
    code: 'SO'
  },
  {
    nameEn: 'South Africa',
    nameEs: 'Sudáfrica',
    dialCode: '+27',
    code: 'ZA'
  },
  {
    nameEn: 'South Sudan',
    nameEs: 'Sudán del Sur',
    dialCode: '+211',
    code: 'SS'
  },
  {
    nameEn: 'Sri Lanka',
    nameEs: 'Sri Lanka',
    dialCode: '+94',
    code: 'LK'
  },
  {
    nameEn: 'State of Palestine',
    nameEs: 'Estado de Palestina',
    dialCode: '+970',
    code: 'PS'
  },
  {
    nameEn: 'Sudan',
    nameEs: 'Sudán',
    dialCode: '+249',
    code: 'SD'
  },
  {
    nameEn: 'Suriname',
    nameEs: 'Surinam',
    dialCode: '+597',
    code: 'SR'
  },
  {
    nameEn: 'Svalbard and Jan Mayen',
    nameEs: 'Svalbard y Jan Mayen',
    dialCode: '+47',
    code: 'SJ'
  },
  {
    nameEn: 'Swaziland',
    nameEs: 'Suazilandia',
    dialCode: '+268',
    code: 'SZ'
  },
  {
    nameEn: 'Sweden',
    nameEs: 'Suecia',
    dialCode: '+46',
    code: 'SE'
  },
  {
    nameEn: 'Switzerland',
    nameEs: 'Suiza',
    dialCode: '+41',
    code: 'CH'
  },
  {
    nameEn: 'Syrian Arab Republic',
    nameEs: 'Siria',
    dialCode: '+963',
    code: 'SY'
  },
  {
    nameEn: 'Taiwan, Province of China',
    nameEs: 'Taiwán',
    dialCode: '+886',
    code: 'TW'
  },
  {
    nameEn: 'Tayikistan',
    nameEs: 'Tayikistán',
    dialCode: '+992',
    code: 'TJ'
  },
  {
    nameEn: 'Tanzania, United Republic of',
    nameEs: 'Tanzania',
    dialCode: '+255',
    code: 'TZ'
  },
  {
    nameEn: 'Thailand',
    nameEs: 'Tailandia',
    dialCode: '+66',
    code: 'TH'
  },
  {
    nameEn: 'Timor-Leste',
    nameEs: 'Timor Oriental',
    dialCode: '+670',
    code: 'TL'
  },
  {
    nameEn: 'Togo',
    nameEs: 'Togo',
    dialCode: '+228',
    code: 'TG'
  },
  {
    nameEn: 'Tokelau',
    nameEs: 'Tokelau',
    dialCode: '+690',
    code: 'TK'
  },
  {
    nameEn: 'Tonga',
    nameEs: 'Tonga',
    dialCode: '+676',
    code: 'TO'
  },
  {
    nameEn: 'Trinidad and Tobago',
    nameEs: 'Trinidad y Tobago',
    dialCode: '+1868',
    code: 'TT'
  },
  {
    nameEn: 'Tunisia',
    nameEs: 'Túnez',
    dialCode: '+216',
    code: 'TN'
  },
  {
    nameEn: 'Turkey',
    nameEs: 'Turquía',
    dialCode: '+90',
    code: 'TR'
  },
  {
    nameEn: 'Turkmenistan',
    nameEs: 'Turkmenistán',
    dialCode: '+993',
    code: 'TM'
  },
  {
    nameEn: 'Turks and Caicos Islands',
    nameEs: 'Islas Turcas y Caicos',
    dialCode: '+1649',
    code: 'TC'
  },
  {
    nameEn: 'Tuvalu',
    nameEs: 'Tuvalu',
    dialCode: '+688',
    code: 'TV'
  },
  {
    nameEn: 'Uganda',
    nameEs: 'Uganda',
    dialCode: '+256',
    code: 'UG'
  },
  {
    nameEn: 'Ukraine',
    nameEs: 'Ucrania',
    dialCode: '+380',
    code: 'UA'
  },
  {
    nameEn: 'United Arab Emirates',
    nameEs: 'Emiratos Árabes Unidos',
    dialCode: '+971',
    code: 'AE'
  },
  {
    nameEn: 'United Kingdom',
    nameEs: 'Reino Unido',
    dialCode: '+44',
    code: 'GB'
  },
  {
    nameEn: 'United States',
    nameEs: 'Estados Unidos',
    dialCode: '+1',
    code: 'US'
  },
  {
    nameEn: 'Uruguay',
    nameEs: 'Uruguay',
    dialCode: '+598',
    code: 'UY'
  },
  {
    nameEn: 'Uzbekistan',
    nameEs: 'Uzbekistán',
    dialCode: '+998',
    code: 'UZ'
  },
  {
    nameEn: 'Vanuatu',
    nameEs: 'Vanuatu',
    dialCode: '+678',
    code: 'VU'
  },
  {
    nameEn: 'Venezuela, Bolivarian Republic of',
    nameEs: 'Venezuela',
    dialCode: '+58',
    code: 'VE'
  },
  {
    nameEn: 'Vietnam',
    nameEs: 'Vietnam',
    dialCode: '+84',
    code: 'VN'
  },
  {
    nameEn: 'Virgin Islands, British',
    nameEs: 'Islas Vírgenes Británicas',
    dialCode: '+1284',
    code: 'VG'
  },
  {
    nameEn: 'Virgin Islands, U.S.',
    nameEs: 'Islas Vírgenes de los Estados Unidos',
    dialCode: '+1340',
    code: 'VI'
  },
  {
    nameEn: 'Wallis and Futuna',
    nameEs: 'Wallis y Futuna',
    dialCode: '+681',
    code: 'WF'
  },
  {
    nameEn: 'Yemen',
    nameEs: 'Yemen',
    dialCode: '+967',
    code: 'YE'
  },
  {
    nameEn: 'Zambia',
    nameEs: 'Zambia',
    dialCode: '+260',
    code: 'ZM'
  },
  {
    nameEn: 'Zimbabwe',
    nameEs: 'Zimbabue',
    dialCode: '+263',
    code: 'ZW'
  },
  {
    nameEn: 'Åland Islands',
    nameEs: 'Åland',
    dialCode: '+358',
    code: 'AX'
  }
].map((c) => ({
  label: `${c.dialCode} ${c.nameEs}`,
  codeDisplay: c.dialCode,
  codeNum: c.dialCode,
  country: c.nameEs
}));

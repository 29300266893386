/**
 * AirplaneResourceCollectionResponseModel
 */
import { AirplaneResourceCollectionResponseInterface } from '../../interfaces/airplane/airplane-resource-collection-response.interface';
import { PaginationModel } from '../pagination.model';
import { AirplaneResourceCollectionModel } from './airplane-resource-collection.model';

export class AirplaneResourceCollectionResponseModel {
  data: AirplaneResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: AirplaneResourceCollectionResponseInterface) {
    this.data = data.data;
    this.pagination = data.pagination;
  }
}

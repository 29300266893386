/**
 * AirplaneResourceModel
 */
import { AirplaneResourceInterface } from '../../interfaces/airplane/airplane-resource.interface';

export class AirplaneResourceModel {
  id?: number;
  code?: string;
  name?: string;
  availableSeats?: number;
  isPresidential?: boolean;

  constructor(data: AirplaneResourceInterface) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.availableSeats = data.availableSeats;
    this.isPresidential = data.isPresidential;
  }
}

/**
 * PlaceResourceModel
 */
import { PlaceResourceInterface } from '../../interfaces/place-resource/place-resource.interface';

export class PlaceResourceModel {
  id?: number;
  code?: string;
  isAirport?: boolean;
  isInternationallyCoded?: boolean;
  name?: string;
  city?: string;
  timeZone?: string;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: PlaceResourceInterface) {
    this.id = data.id;
    this.code = data.code;
    this.isAirport = data.isAirport;
    this.isInternationallyCoded = data.isInternationallyCoded;
    this.name = data.name;
    this.city = data.city;
    this.timeZone = data.timeZone;
  }
}

import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';

import { ModalsService } from '@services/modals/modals.service';

@Injectable({
  providedIn: 'root'
})
export class ModalsGuard implements CanActivateChild {
  constructor(private modalService: ModalsService) {}

  canActivateChild() {
    const opened = !this.modalService.openedModal;
    this.modalService.closeModal();
    return opened;
  }
}

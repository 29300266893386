import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';

import { MDialogRef, MDialogService } from '@mercadona/components/dialog';

import { ModalConfig } from '../../utils/device/device';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  openedModal: MDialogRef | undefined;

  constructor(private readonly mDialogService: MDialogService) {}

  openModal(
    component: TemplateRef<unknown> | ComponentType<unknown>,
    data: object | undefined = {}
  ): MDialogRef<unknown> {
    data = { ...this.#getConfig(), ...data };
    this.blockBodyScroll(true);
    this.openedModal = this.mDialogService.open(component, data);
    this.openedModal.afterClosed().subscribe(() => {
      this.blockBodyScroll(false);
      this.openedModal = undefined;
    });
    return this.openedModal;
  }

  openAlert(component: TemplateRef<unknown> | ComponentType<unknown>): MDialogRef<unknown> {
    this.blockBodyScroll(true);
    this.openedModal = this.mDialogService.open(component);
    this.openedModal.afterClosed().subscribe(() => {
      this.blockBodyScroll(false);
      this.openedModal = undefined;
    });
    return this.openedModal;
  }

  closeModal(): void {
    this.blockBodyScroll(false);
    this.openedModal?.close();
    this.openedModal = undefined;
  }

  getConfigWithoutSize(): ModalConfig {
    return { panelClass: ['dialog', 'no-size'], autoFocus: false };
  }

  #getConfig(): ModalConfig {
    return { panelClass: ['dialog'], autoFocus: false };
  }

  blockBodyScroll(block: boolean): void {
    if (block) {
      document.body.classList.add('body-scroll-lock');
    } else {
      document.body.classList.remove('body-scroll-lock');
    }
  }
}

/* eslint-disable jsdoc/require-jsdoc */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { isAfter, isSameDay } from 'date-fns';

export function dateAfterNow(timeControl: AbstractControl): ValidatorFn {
  return (dateControl: AbstractControl): { [key: string]: AbstractControl } | null =>
    isAfterNow(dateControl, timeControl) ? null : { dateAfterNow: dateControl.value };
}

export function timeAfterNow(dateControl: AbstractControl): ValidatorFn {
  return (timeControl: AbstractControl): { [key: string]: AbstractControl } | null =>
    isAfterNow(dateControl, timeControl) ? null : { timeAfterNow: dateControl.value };
}

function isAfterNow(dateControl: AbstractControl, timeControl: AbstractControl): boolean {
  const date = recoverDateFromControl(dateControl, timeControl);
  if (!date || isNaN(date.valueOf())) {
    return true;
  }
  return isAfter(date, new Date());
}

export function dateAfterDate(
  timeControl: AbstractControl,
  dateRef: AbstractControl,
  timeRef: AbstractControl
): ValidatorFn {
  return (dateControl: AbstractControl): { [key: string]: AbstractControl } | null =>
    isAfterDate(dateControl, timeControl, dateRef, timeRef) ? null : { dateAfterDate: dateControl.value };
}

export function timeAfterDate(
  dateControl: AbstractControl,
  dateRef: AbstractControl,
  timeRef: AbstractControl
): ValidatorFn {
  return (timeControl: AbstractControl): { [key: string]: AbstractControl } | null =>
    isAfterDate(dateControl, timeControl, dateRef, timeRef) ? null : { timeAfterDate: dateControl.value };
}

function isAfterDate(
  dateControl: AbstractControl,
  timeControl: AbstractControl,
  dateRef: AbstractControl,
  timeRef: AbstractControl
) {
  const date1 = recoverDateFromControl(dateControl, timeControl);
  const date2 = recoverDateFromControl(dateRef, timeRef);
  const time1 = timeControl?.value;
  if (!date1) {
    return null;
  } else if (date1 && (time1 === null || time1 === '' || time1.hours === undefined)) {
    return null;
  } else if (date1 && date2) {
    return isAfter(date1, date2);
  }

  return null;
}

function recoverDateFromControl(dateControl: AbstractControl, timeControl: AbstractControl): Date | null {
  const dateValue = dateControl.value;
  const date = dateValue ? new Date(dateValue) : null;

  if (!date) {
    return null;
  }

  const time = timeControl.value;
  if (time?.hours) {
    date.setHours(time.hours);
  }
  if (time?.minutes) {
    date.setMinutes(time.minutes);
  }

  return date;
}

export class WhiteSpaceValidator {
  // eslint-disable-next-line complexity
  static minValidCharLength(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && control.value.length > 0 && control.value.trim().length < length) {
        return { minValidCharLength: true };
      }

      return null;
    };
  }
}

export class CheckboxValidator {
  static atLeastOneRequired(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.find((v: boolean) => v === true)) {
      return null;
    }
    return { atLeastOneRequired: true };
  }
}

export class DateValidators {
  static isTodayOrAfter(dateControl: AbstractControl): ValidationErrors | null {
    if (!dateControl?.value) {
      return null;
    }

    const today = new Date();
    const date = new Date(dateControl.value);

    return isSameDay(today, date) || isAfter(date, today) ? null : { isTodayOrAfter: dateControl.value };
  }

  static required(dateControl: AbstractControl): ValidationErrors | null {
    const date = new Date(dateControl.value);

    if (!date || !(date instanceof Date) || isNaN(date.valueOf())) {
      return { required: dateControl.value };
    }

    return null;
  }
}

/**
 * DepartmentResourceModel
 */
import { DepartmentResourceInterface } from '../../interfaces/departament-resource/department-resource.interface';

export class DepartmentResourceModel {
  id?: number;
  name?: string;
  description?: string;
  organizationUnitCode?: string;
  email?: string;
  login?: string;
  phoneNumber?: string;

  constructor(data: DepartmentResourceInterface) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.organizationUnitCode = data.organizationUnitCode;
    this.email = data.email;
    this.login = data.login;
    this.phoneNumber = data.phoneNumber;
  }
}

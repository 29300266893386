import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ProfilingDirectiveModule } from '@directives/profiling/profiling.directive.module';

import { MBadgeModule } from '@mercadona/components/badge';
import { MButtonModule } from '@mercadona/components/button';
import { MTranslateModule } from '@mercadona/core/translate';
import { MIconModule } from '@mercadona/icons';

import { HeaderActionsComponent } from './header-actions.component';

/**
 * HeaderActionsModule
 */
@NgModule({
  declarations: [HeaderActionsComponent],
  imports: [CommonModule, MButtonModule, ProfilingDirectiveModule, MTranslateModule, MIconModule, MBadgeModule],
  exports: [HeaderActionsComponent]
})
export class HeaderActionsModule {}

import { Injectable } from '@angular/core';

import { UsersRepository } from './users.repository';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private usersRepository: UsersRepository) {}

  getUserInfo() {
    return this.usersRepository.getUserInfo();
  }

  getUserBookings(params?: {
    departure?: number;
    destination?: number;
    outboundDateFrom?: string;
    outboundDateTo?: string;
    isPlaceConfirmed?: boolean;
    isMyOwnTavel?: boolean;
    firstPage?: number;
    pageSize?: number;
  }) {
    return this.usersRepository.getUserBookings(params);
  }
}

import { Injectable } from '@angular/core';

import { StatesStageRepository } from './states-stage.repository';

@Injectable({
  providedIn: 'root'
})
export class StatesStageService {
  constructor(private statesStageRepository: StatesStageRepository) {}

  getStatesStage() {
    return this.statesStageRepository.getStatesStage();
  }

  getStageStatesTransitions() {
    return this.statesStageRepository.getStageStatesTransitions();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { APP_CONFIG } from '@constants';

import { MMenuModule } from '@mercadona/components/menu';
import { MTranslateModule } from '@mercadona/core/translate';

import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, MMenuModule, MTranslateModule.forRoot(APP_CONFIG.language)],
  exports: [MenuComponent]
})
export class MenuModule {}

/**
 * AirplaneResourceResponseModel
 */
import { AirplaneResourceResponseInterface } from '../../interfaces/airplane/airplane-resource-response.interface';
import { AirplaneResourceModel } from './airplane-resource.model';

export class AirplaneResourceResponseModel {
  data?: AirplaneResourceModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: AirplaneResourceResponseInterface) {
    this.data = data.data;
  }
}

import { StageResourceInterface, StatusEnum } from '../../interfaces/stage-resource/stage-resource.interface';
import { PlaceResourceModel } from '../place-resource/place-resource.model';

/**
 * StageResourceModel
 */
export class StageResourceModel {
  id?: number;
  statusCode?: StatusEnum;
  code?: string;
  createdDate?: string;
  personIdCreated?: number;
  canceledDate?: string;
  canceledReasonId?: number;
  personIdCanceled?: number;
  closedDate?: string;
  personIdClosed?: number;
  closedReasonDesc?: string;
  outboundDate?: string;
  inboundDate?: string;
  departure?: PlaceResourceModel;
  destination?: PlaceResourceModel;
  destinationRemarks?: string;
  seatsTotal?: number;
  seatsOccupied?: number;
  seatsWaitingList?: number;
  isPositional?: boolean;
  anotherDeparture?: string;
  anotherDestination?: string;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: StageResourceInterface) {
    this.id = data.id;
    this.statusCode = data.statusCode;
    this.code = data.code;
    this.createdDate = data.createdDate;
    this.personIdCreated = data.personIdCreated;
    this.canceledDate = data.canceledDate;
    this.canceledReasonId = data.canceledReasonId;
    this.personIdCanceled = data.personIdCanceled;
    this.closedDate = data.closedDate;
    this.personIdClosed = data.personIdClosed;
    this.closedReasonDesc = data.closedReasonDesc;
    this.outboundDate = data.outboundDate;
    this.inboundDate = data.inboundDate;
    this.departure = data.departure ? new PlaceResourceModel(data.departure) : undefined;
    this.destination = data.destination ? new PlaceResourceModel(data.destination) : undefined;
    this.destinationRemarks = data.destinationRemarks;
    this.seatsTotal = data.seatsTotal;
    this.seatsOccupied = data.seatsOccupied;
    this.seatsWaitingList = data.seatsWaitingList;
    this.isPositional = data.isPositional;
    this.anotherDeparture = data.anotherDeparture;
    this.anotherDestination = data.anotherDestination;
  }
}

import { PersonResourceResponseInterface } from '../../interfaces/person-resource/person-resource-response.interface';
import { PersonExtendedResourceModel } from '../person-extended/person-extended-resource.model';

/**
 * PersonResourceResponseModel
 */
export class PersonResourceResponseModel {
  data?: PersonExtendedResourceModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: PersonResourceResponseInterface) {
    this.data = data.data ? new PersonExtendedResourceModel(data.data) : undefined;
  }
}

export { PersonExtendedResourceModel };

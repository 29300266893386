import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { APP_CONFIG } from '@constants';
import { environment } from '@environment';
import { AppManagerService } from '@services/app-manager/app-manager.service';
import { AuthorizationClass } from '@shared/authorization/authorization.class';
import { MenuInterface } from '@shared/consts/menu.const';

import { MDialogConfirmData, MDialogService } from '@mercadona/components/dialog';
import { MAuthTokenLegacyService, MToken } from '@mercadona/core/auth-token-legacy';
import { MPlatformEnvironment } from '@mercadona/core/platform';
import { MLocalStorageService } from '@mercadona/core/storage';
import { MTranslateService } from '@mercadona/core/translate';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends AuthorizationClass implements OnInit {
  menu: MenuInterface[] = [];
  showInteractionShield: boolean = false;
  showLateralMenu: boolean = false;
  languages: string[] = [...APP_CONFIG.language.availableLanguages];
  userNameInitials: string = '??';
  userName: string = '?? ??';
  environmentName: string = this.#getEnvironmentCustomTitle();
  customHeaderColor: string = this.#getEnvironmentCustomHeaderColor();

  constructor(
    private router: Router,
    private mTranslateService: MTranslateService,
    private mLocalStorage: MLocalStorageService,
    private mDialogService: MDialogService,
    private mAuthTokenService: MAuthTokenLegacyService,
    private appManagerService: AppManagerService
  ) {
    super();
    this.isAuthenticatedService();
  }

  ngOnInit() {
    this.appManagerService.menuSubject.subscribe((menu) => (this.menu = menu));
    this.appManagerService.displayG200Subject.subscribe(() => {
      this.appManagerService.menuSubject.next(
        this.appManagerService.menuSubject.value.filter(this.filterRetailMenuOptions.bind(this))
      );
    });
  }

  toggleLateralMenu() {
    this.showInteractionShield = !this.showInteractionShield;
    this.showLateralMenu = !this.showLateralMenu;
  }

  get currentLanguage(): string {
    return this.mTranslateService.getActiveLang();
  }

  setAppLanguage(index: number) {
    this.mTranslateService.setActiveLang(this.languages[index]);
    this.mLocalStorage.set('language', this.languages[index]);
    this.toggleLateralMenu();

    const uri = `${this.router.url}`;
    this.router
      .navigateByUrl('/refresh-page', { skipLocationChange: true })
      .then(() => this.router.navigate([`${uri}`]));
  }

  showLogoutDialog() {
    const dialogConfirmDialogData: MDialogConfirmData = {
      message: this.mTranslateService.translate('COMMON.WANT_LEAVE_APP'),
      infoLabel: this.mTranslateService.translate('COMMON.CONFIRMATION'),
      confirmButtonLabel: this.mTranslateService.translate('COMMON.LEAVE'),
      cancelButtonLabel: this.mTranslateService.translate('COMMON.CANCEL'),
      state: 'destructive'
    };

    this.mDialogService
      .openConfirm(dialogConfirmDialogData)
      ?.afterClosed()
      ?.subscribe(this.evalLogoutConfirm.bind(this));
  }

  private isAuthenticatedService() {
    this.mAuthTokenService.isAuthenticated$.subscribe((isAuth: boolean) => {
      if (isAuth) {
        this.mAuthTokenService.getToken(environment.configToken.audience).subscribe((token: MToken) => {
          this.setUserNameAndInitials(token as unknown as MToken);
        });
      } else {
        this.mAuthTokenService.login().then(() => {
          this.mLocalStorage.clear();
        });
      }
    });
  }

  evalLogoutConfirm(response: boolean) {
    if (response) {
      this.mAuthTokenService.logout().then(() => {
        this.mLocalStorage.clear();
      });
    }
  }

  setUserNameAndInitials(token: MToken) {
    const firstName: string | unknown = token.accessTokenDecoded?.given_name || '??';
    const surname: string | unknown = token.accessTokenDecoded?.family_name || '??';
    this.userName = firstName + ' ' + surname;
    this.userNameInitials = this.userName
      .split(' ', 3)
      .map((name) => name[0])
      .reduce((initials, newInitial) => initials + newInitial);
  }

  #getEnvironmentCustomTitle() {
    return environment.env !== ('pro' as MPlatformEnvironment) ? `${' (' + environment.env.toUpperCase() + ')'}` : '';
  }

  #getEnvironmentCustomHeaderColor() {
    if (environment.env === 'local') {
      return 'rgb(158, 158, 158)';
    }

    if (environment.env === 'dev') {
      return 'rgb(100, 149, 237)';
    }

    if (environment.env === 'itg') {
      return 'rgb(253, 173, 115)';
    }

    if (environment.env === 'pre') {
      return 'rgb(255, 155, 225)';
    }

    return '';
  }

  filterRetailMenuOptions(menu: MenuInterface) {
    if (menu.child) {
      menu.child = menu.child.filter(this.filterRetailMenuOptions.bind(this));
    }

    return this.appManagerService.displayG200Subject.value || !menu.onlyVisibleForRetail;
  }
}

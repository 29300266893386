import { Component, HostListener, OnInit } from '@angular/core';

import { AppManagerService } from '@services/app-manager/app-manager.service';
import { IconService } from '@services/icon/icon.service';
import { AuthService } from '@shared/authorization/auth.service';
import { AuthorizationClass } from '@shared/authorization/authorization.class';

import { setTheme, themes } from '@mercadona/styles';

export interface IMAccessToken {
  preferredlanguage: string;
  operationgroups: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends AuthorizationClass implements OnInit {
  themes: string[] = [...themes];
  vh!: number;

  constructor(
    private iconService: IconService,
    private appManagerService: AppManagerService,
    private authService: AuthService
  ) {
    super();
    this.iconService.initIcons();
    setTheme(this.themes[0]);

    setTimeout(() => {
      this.authService.checkAuth();
    }, 0);
  }
  ngOnInit() {
    this.setVhProperty();
    window.addEventListener('resize', this.setVhProperty.bind(this));
  }

  @HostListener('window:orientationchange')
  onOrientationChange() {
    this.setVhProperty();
  }

  setVhProperty() {
    this.vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
  }
}

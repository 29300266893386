<m-menu #menuElement [type]="isLateral ? 'vertical' : 'horizontal'" [class.d-none]="!isLateral" class="d-md-flex">
  <m-menu-item
    *ngFor="let m of menu; let i = index; trackBy: trackByMenu"
    [disabled]="m.disabled"
    (click)="!m.child?.length ? itemSelected() : null"
    [link]="m.link ? m.link : ''"
    [url]="m.url ? m.url : ''">
    {{ m.label | mTranslate }}
    <m-menu-group>
      <m-menu-item
        *ngFor="let m1 of m.child; let i = index; trackBy: trackByMenu"
        [disabled]="m.disabled"
        (click)="isLateral ? itemSelected() : null"
        [link]="m1.link ? m1.link : ''"
        [url]="m1.url ? m1.url : ''">
        <span>
          {{ m1.label | mTranslate }}
        </span>
      </m-menu-item>
    </m-menu-group>
  </m-menu-item>
  <m-menu-divider *ngIf="isLateral"></m-menu-divider>
</m-menu>

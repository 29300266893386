import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MenuModule } from '@components/menu/menu.module';
import { ProfilingDirectiveModule } from '@directives/profiling/profiling.directive.module';

import { MButtonModule } from '@mercadona/components/button';
import { MContextMenuModule } from '@mercadona/components/context-menu';
import { MHeaderModule } from '@mercadona/components/header';
import { MLinkModule } from '@mercadona/components/link';
import { MMenuModule } from '@mercadona/components/menu';
import { MTranslateModule } from '@mercadona/core/translate';
import { MIconModule } from '@mercadona/icons';

import { APP_CONFIG } from '../../app-config.constants';
import { HeaderComponent } from './header.component';

/**
 * HeaderModule
 */
@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    MHeaderModule,
    MTranslateModule.forRoot(APP_CONFIG.language),
    MIconModule,
    MContextMenuModule,
    MLinkModule,
    MButtonModule,
    RouterModule,
    MMenuModule,
    MenuModule,
    ProfilingDirectiveModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}

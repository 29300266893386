import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PersonResourceCollectionResponseInterface } from '@interfaces/person-resource/person-resource-collection-response.interface';
import { PersonResourceResponseInterface } from '@interfaces/person-resource/person-resource-response.interface';
import { PersonResourceInterface } from '@interfaces/person-resource/person-resource.interface';
import { PersonResourceCollectionResponseModel } from '@models/person-resource/person-resource-collection-response.model';
import { PersonResourceRequestModel } from '@models/person-resource/person-resource-request.model';
import { PersonResourceResponseModel } from '@models/person-resource/person-resource-response.model';
import { PersonResourceModel } from '@models/person-resource/person-resource.model';
import { PersonSimpleResourceRequestModel } from '@models/person-resource/person-simple-resource-request.model';
import { Repository } from '@services/abstract.repository';

@Injectable({
  providedIn: 'root'
})
export class PersonsRepository extends Repository {
  baseUrl = `${this.basePath}/persons`;

  constructor(injector: Injector) {
    super(injector);
  }

  getPersons(params?: {
    search?: string;
    typeOfPersonIds?: string;
    societyId?: number;
    departmentId?: number;
    isUpdatedExternally?: boolean;
    isManually?: boolean;
    firstPage?: number;
    pageSize?: number;
  }): Observable<PersonResourceCollectionResponseModel> {
    return this.doRequest<PersonResourceCollectionResponseInterface>('get', `${this.baseUrl}`, params).pipe(
      map((persons) => new PersonResourceCollectionResponseModel(persons))
    );
  }

  createPerson(data: PersonResourceRequestModel): Observable<PersonResourceModel> {
    return this.doRequest<PersonResourceInterface>('post', `${this.baseUrl}`, undefined, { body: data }).pipe(
      map((person) => new PersonResourceModel(person))
    );
  }

  getPerson(personId: number): Observable<PersonResourceResponseModel> {
    return this.doRequest<PersonResourceResponseInterface>('get', `${this.baseUrl}/${personId}`).pipe(
      map((person) => new PersonResourceResponseModel(person))
    );
  }

  updatePerson(personId: number, data: PersonSimpleResourceRequestModel): Observable<PersonResourceResponseModel> {
    return this.doRequest<PersonResourceResponseInterface>('put', `${this.baseUrl}/${personId}`, undefined, {
      body: data
    }).pipe(map((person) => new PersonResourceResponseModel(person)));
  }

  deletePerson(personId: number): Observable<void> {
    return this.doRequest<void>('delete', `${this.baseUrl}/${personId}`);
  }
}

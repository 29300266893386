import { PersonExtendedResourceInterface } from '../../interfaces/person-extended/person-extended-resource.interface';
import { SocietyResourceModel } from '../society-resource/society-resource.model';
import { TypeOfPersonResourceModel } from '../type-of-person/type-of-person-resource.model';

/**
 * PersonExtendedResourceModel
 */
export class PersonExtendedResourceModel {
  id?: number;
  typeOfPerson?: TypeOfPersonResourceModel;
  registrationNumber?: string;
  employeeId?: number;
  name?: string;
  firstSurname?: string;
  secondSurname?: string;
  login?: string;
  email?: string;
  emailTravel?: string;
  society?: SocietyResourceModel;
  birthDate?: string;
  costCenterCode?: string;
  departmentId?: number;
  isManually?: boolean;
  defaultLocale?: string;
  isUpdatedExternally?: boolean;
  externallyUpdatedDate?: string;
  identityDocumentNumber?: string;
  identityDocumentExpirationDate?: string;
  passportDocumentNumber?: string;
  passportDocumentExpirationDate?: string;
  phoneNumber?: string;
  phoneNumberPrefix?: string;
  phoneNumberTravel?: string;
  phoneNumberTravelPrefix?: string;
  isRetailCompany?: boolean;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: PersonExtendedResourceInterface) {
    this.id = data.id;
    this.typeOfPerson = data.typeOfPerson ? new TypeOfPersonResourceModel(data.typeOfPerson) : undefined;
    this.registrationNumber = data.registrationNumber;
    this.employeeId = data.employeeId;
    this.name = data.name;
    this.firstSurname = data.firstSurname;
    this.secondSurname = data.secondSurname;
    this.login = data.login;
    this.email = data.email;
    this.emailTravel = data.emailTravel;
    this.society = data.society ? new SocietyResourceModel(data.society) : undefined;
    this.birthDate = data.birthDate;
    this.costCenterCode = data.costCenterCode;
    this.departmentId = data.departmentId;
    this.isManually = data.isManually;
    this.defaultLocale = data.defaultLocale;
    this.isUpdatedExternally = data.isUpdatedExternally;
    this.externallyUpdatedDate = data.externallyUpdatedDate;
    this.identityDocumentNumber = data.identityDocumentNumber;
    this.identityDocumentExpirationDate = data.identityDocumentExpirationDate;
    this.passportDocumentNumber = data.passportDocumentNumber;
    this.passportDocumentExpirationDate = data.passportDocumentExpirationDate;
    this.phoneNumber = data.phoneNumber;
    this.phoneNumberPrefix = data.phoneNumberPrefix;
    this.phoneNumberTravel = data.phoneNumberTravel;
    this.phoneNumberTravelPrefix = data.phoneNumberTravelPrefix;
    this.isRetailCompany = data.isRetailCompany;
  }

  get fullNameAndSociety() {
    return `${this.name} ${this.firstSurname} ${this.secondSurname} - ${this.society?.name ?? ''}`;
  }
}

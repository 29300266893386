import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { MenuInterface } from '@shared/consts/menu.const';

import { MSafeAny } from '@mercadona/common/private';
import { MMenuComponent } from '@mercadona/components/menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() menu: MenuInterface[] = [];
  @Input() isLateral = false;
  @Output() itemSelectedEvent = new EventEmitter<void>();
  @ViewChild('menuElement') menuElement!: MMenuComponent;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: MSafeAny) => {
      if (event.url === '/') {
        this.menuElement.unselectAll();
      }
    });
  }

  trackByMenu(_i: number, menu: MenuInterface): string {
    return menu.label;
  }

  itemSelected() {
    this.itemSelectedEvent.emit();
  }
}

import { CancellationReasonResourceResponseInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource-response.interface';
import { CancellationReasonResourceModel } from './cancellation-reason-resource.model';

/**
 * CancellationReasonResourceResponseModel
 */
export class CancellationReasonResourceResponseModel {
  data?: CancellationReasonResourceModel;

  constructor(data: CancellationReasonResourceResponseInterface) {
    this.data = data.data;
  }
}

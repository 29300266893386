import { CancellationReasonResourceCollectionInterface } from '../../interfaces/cancelation-reason/cancellation-reason-resource-collection.interface';
import { CancellationReasonResourceModel } from './cancellation-reason-resource.model';

/**
 * CancellationReasonResourceCollectionModel
 */
export class CancellationReasonResourceCollectionModel {
  cancellationReasons?: CancellationReasonResourceModel[];
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: CancellationReasonResourceCollectionInterface) {
    this.cancellationReasons = data.cancellationReasons
      ? data.cancellationReasons?.map((reason) => new CancellationReasonResourceModel(reason))
      : [];
  }
}

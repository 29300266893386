import { DepartmentResourceCollectionResponseInterface } from '../../interfaces/departament-resource/department-resource-collection-response.interface';
import { PaginationModel } from '../pagination.model';
import { DepartmentResourceCollectionModel } from './department-resource-collection.model';

/**
 * DepartmentResourceCollectionResponseModel
 */
export class DepartmentResourceCollectionResponseModel {
  data: DepartmentResourceCollectionModel;
  pagination: PaginationModel;
  /**
   * Model constructor
   *
   * @param data
   */
  constructor(data: DepartmentResourceCollectionResponseInterface) {
    this.data = new DepartmentResourceCollectionModel(data.data);
    this.pagination = new PaginationModel(data.pagination);
  }
}

import { MyBookingsResourceInterface } from '@pages/g200/pages/bookings/interface/my-bookings-resource.interface';
import { JourneySimpleResourceModel } from '@pages/g200/pages/journeys/models/journey-simple-resource.model';
import { StageResourceModel } from '@shared/models/stage-resource/stage-resource.model';

export class MyBookingsResourceModel {
  isSomeoneInWaitingList?: boolean;
  stage?: StageResourceModel;
  journey?: JourneySimpleResourceModel;

  constructor(data: MyBookingsResourceInterface) {
    this.isSomeoneInWaitingList = data.isSomeoneInWaitingList;
    this.stage = data.stage ? new StageResourceModel(data.stage) : undefined;
    this.journey = data.journey ? new JourneySimpleResourceModel(data.journey) : undefined;
  }
}

import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { HTTPInterceptor } from './http-interceptor';

/**
 * HTTPInterceptorModule
 */
@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HTTPInterceptor,
      multi: true
    }
  ]
})
export class HTTPInterceptorModule {}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PersonResourceResponseInterface } from '@interfaces/person-resource/person-resource-response.interface';
import { PersonResourceResponseModel } from '@models/person-resource/person-resource-response.model';
import { MyBookingsResourceCollectionResponseInterface } from '@pages/g200/pages/bookings/interface/my-bookings-resource-collection-response.interface';
import { MyBookingsResourceCollectionResponseModel } from '@pages/g200/pages/bookings/models/my-bookings-resource-collection-response.model';

import { Repository } from '../abstract.repository';

@Injectable({
  providedIn: 'root'
})
export class UsersRepository extends Repository {
  baseUrl = `${this.basePath}/me`;

  constructor(injector: Injector) {
    super(injector);
  }

  getUserInfo(): Observable<PersonResourceResponseModel> {
    return this.doRequest<PersonResourceResponseInterface>('get', `${this.baseUrl}`).pipe(
      map((user) => new PersonResourceResponseModel(user))
    );
  }

  getUserBookings(params?: {
    departure?: number;
    destination?: number;
    outboundDateFrom?: string;
    outboundDateTo?: string;
    isPlaceConfirmed?: boolean;
    isMyOwnTavel?: boolean;
    firstPage?: number;
    pageSize?: number;
  }): Observable<MyBookingsResourceCollectionResponseModel> {
    return this.doRequest<MyBookingsResourceCollectionResponseInterface>(
      'get',
      `${this.baseUrl}/bookings`,
      params
    ).pipe(map((bookings) => new MyBookingsResourceCollectionResponseModel(bookings)));
  }
}

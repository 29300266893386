<div class="d-none d-md-inline-flex">
  <div *ngFor="let action of actions; trackBy: trackByIndexAction">
    <button
      m-button
      size="small"
      class="ms-1"
      [color]="action.color"
      [buttonType]="action.type"
      [disabled]="action.disabled ?? false"
      (click)="action.action()"
      *profiling="action.roles">
      <span>{{ action.text | mTranslate }}</span>
      <m-icon *ngIf="action?.icon?.name && !action.badge" [iconName]="action.icon?.name!"></m-icon>
      <m-icon
        *ngIf="action?.icon?.name && action.badge"
        m-badge=""
        badgeColor="alert"
        badgePosition="top-right"
        badgeType="dot"
        [iconName]="action.icon?.name!"></m-icon>
    </button>
  </div>
</div>

<div class="d-inline-flex d-md-none">
  <div *ngFor="let action of actions; trackBy: trackByIndexAction">
    <ng-container *ngIf="action?.icon?.name">
      <button
        m-button
        sizeIcon="normal"
        class="ms-1"
        buttonType="icon"
        [color]="action.color"
        *profiling="action.roles"
        (click)="action.action()">
        <m-icon *ngIf="!action.badge" [iconName]="action.icon?.name!"></m-icon>
        <m-icon
          *ngIf="action.badge"
          m-badge=""
          badgeColor="alert"
          badgePosition="top-right"
          badgeType="dot"
          [iconName]="action.icon?.name!"></m-icon>
      </button>
    </ng-container>
  </div>
</div>

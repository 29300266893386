import { AfterViewInit, Component, ElementRef, Inject, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { M_DIALOG_DATA, M_DIALOG_REF, MDialogRef } from '@mercadona/components/dialog';

export interface HtmlModalData {
  title: string;
  html: string;
}

@Component({
  selector: 'app-html-modal',
  templateUrl: './html-modal.component.html',
  styleUrls: ['./html-modal.component.scss']
})
export class HtmlModalComponent implements AfterViewInit {
  @ViewChild('modalContentContainer') modalContentContainer!: ElementRef;
  title: string;

  constructor(
    @Inject(M_DIALOG_REF) private readonly dialogRef: MDialogRef<HtmlModalComponent>,
    @Inject(M_DIALOG_DATA) private readonly data: HtmlModalData,
    private domSanitizer: DomSanitizer
  ) {
    this.title = this.data.title;
  }

  ngAfterViewInit() {
    if (this.modalContentContainer?.nativeElement) {
      this.#setModalContent(this.data.html);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  #setModalContent(data: string) {
    this.modalContentContainer.nativeElement.innerHTML = this.domSanitizer.sanitize(
      SecurityContext.STYLE,
      data
    ) as string;
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DepartmentResourceCollectionResponseInterface } from '../../interfaces/departament-resource/department-resource-collection-response.interface';
import { DepartmentResourceResponseInterface } from '../../interfaces/departament-resource/department-resource-response.interface';
import { DepartmentResourceCollectionResponseModel } from '../../models/departament-resource/department-resource-collection-response.model';
import { DepartmentResourceResponseModel } from '../../models/departament-resource/department-resource-response.model';
import { Repository } from '../abstract.repository';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsRepository extends Repository {
  baseUrl = `${this.basePath}/departments`;

  constructor(injector: Injector) {
    super(injector);
  }

  getDepartments(params?: {
    description?: string;
    pageSize?: number;
    firstPage?: number;
  }): Observable<DepartmentResourceCollectionResponseModel> {
    return this.doRequest<DepartmentResourceCollectionResponseInterface>('get', `${this.baseUrl}`, params).pipe(
      map((departments) => new DepartmentResourceCollectionResponseModel(departments))
    );
  }

  getDepartment(departmentId: number): Observable<DepartmentResourceResponseModel> {
    return this.doRequest<DepartmentResourceResponseInterface>('get', `${this.baseUrl}/${departmentId}`).pipe(
      map((department) => new DepartmentResourceResponseModel(department))
    );
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorizationGuard } from 'app/guards/authorization/authorization.guard';
import { RolesEnum } from 'app/shared/consts/roles.const';

import { AdministrationPageComponent } from './administration-page.component';

const routes: Routes = [
  {
    path: '',
    component: AdministrationPageComponent,
    canActivate: [AuthorizationGuard],
    data: {
      roles: [RolesEnum.ROLE_ADMIN]
    },
    children: [
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/user-management/user-management-page.module').then((m) => m.UsersAdministrationModule)
      },
      {
        path: 'notices',
        loadChildren: () => import('./pages/notices/notices-page.module').then((m) => m.NoticesPageModule)
      },
      {
        path: 'links',
        loadChildren: () => import('./pages/links/links-page.module').then((m) => m.LinksPageModule)
      },
      {
        path: 'information',
        loadChildren: () => import('./pages/information/information-page.module').then((m) => m.InformationPageModule)
      },
      {
        path: 'indicators',
        loadChildren: () => import('./pages/indicators/indicators-page.module').then((m) => m.IndicatorsPageModule)
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./pages/notifications/notifications-page.module').then((m) => m.NotificationsPageModule)
      },
      {
        path: 'vehicles',
        loadChildren: () => import('./pages/vehicles/vehicles-page.module').then((m) => m.VehiclesPageModule)
      }
    ]
  }
];

/**
 * AdministrationPageRoutingModule
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationPageRoutingModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MAlertModule } from '@mercadona/components/alert';
import { MButtonModule } from '@mercadona/components/button';
import { MCardModule } from '@mercadona/components/card';
import { MCheckboxModule } from '@mercadona/components/checkbox';
import { MChipModule } from '@mercadona/components/chip';
import { MContextMenuModule } from '@mercadona/components/context-menu';
import { MDatepickerModule } from '@mercadona/components/datepicker';
import { MDialogModule } from '@mercadona/components/dialog';
import { MFormFieldModule } from '@mercadona/components/form-field';
import { MHeaderModule } from '@mercadona/components/header';
import { MInfiniteLoaderModule } from '@mercadona/components/infinite-loader';
import { MInputModule } from '@mercadona/components/input';
import { MLinkModule } from '@mercadona/components/link';
import { MListModule } from '@mercadona/components/list';
import { MMenuModule } from '@mercadona/components/menu';
import { MNumberInputModule } from '@mercadona/components/number-input';
import { MPaginatorModule } from '@mercadona/components/paginator';
import { MRadioButtonModule } from '@mercadona/components/radio-button';
import { MSelectModule } from '@mercadona/components/select';
import { MSpinnerModule } from '@mercadona/components/spinner';
import { MStepperModule } from '@mercadona/components/stepper';
import { MSwitchModule } from '@mercadona/components/switch';
import { MTabModule } from '@mercadona/components/tab';
import { MTableModule } from '@mercadona/components/table';
import { MTimepickerModule } from '@mercadona/components/timepicker';
import { MTooltipModule } from '@mercadona/components/tooltip';
import { MPageNotFoundModule } from '@mercadona/core-ui/page-not-found';
import { MIconModule } from '@mercadona/icons';

export const merModules = [
  MAlertModule,
  MChipModule,
  MTabModule,
  MTableModule,
  MTooltipModule,
  MIconModule,
  MFormFieldModule,
  MInfiniteLoaderModule,
  MInputModule,
  MSelectModule,
  MSpinnerModule,
  MDatepickerModule,
  MNumberInputModule,
  MCheckboxModule,
  MButtonModule,
  MHeaderModule,
  MTimepickerModule,
  MPageNotFoundModule,
  MRadioButtonModule,
  MLinkModule,
  MListModule,
  MMenuModule,
  MCardModule,
  MContextMenuModule,
  MDialogModule,
  MStepperModule,
  MSwitchModule,
  MPaginatorModule
];
@NgModule({
  declarations: [],
  imports: [CommonModule, ...merModules],
  exports: [...merModules]
})
export class MercadonaModule {}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TypeOfPersonResourceCollectionResponseInterface } from '../../interfaces/type-of-person/type-of-person-resource-collection-response.interface';
import { TypeOfPersonResourceCollectionResponseModel } from '../../models/type-of-person/type-of-person-resource-collection-response.model';
import { Repository } from '../abstract.repository';
/**
 * PlacesService
 */
@Injectable({
  providedIn: 'root'
})
export class TypesOfPersonsRepository extends Repository {
  baseUrl = `${this.basePath}/types-of-persons`;

  constructor(injector: Injector) {
    super(injector);
  }

  getTypesOfPersons(params?: {
    isCrew?: boolean;
    firstPage?: number;
    pageSize?: number;
    isDriver?: boolean;
  }): Observable<TypeOfPersonResourceCollectionResponseModel> {
    return this.doRequest<TypeOfPersonResourceCollectionResponseInterface>('get', `${this.baseUrl}`, params).pipe(
      map((types) => new TypeOfPersonResourceCollectionResponseModel(types))
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderActionsModule } from '@components/header-actions/header-actions.module';
import { APP_CONFIG } from '@constants';
import { ProfilingDirectiveModule } from '@directives/profiling/profiling.directive.module';

import { MAlertModule } from '@mercadona/components/alert';
import { MBadgeModule } from '@mercadona/components/badge';
import { MButtonModule } from '@mercadona/components/button';
import { MLinkModule } from '@mercadona/components/link';
import { MTranslateModule } from '@mercadona/core/translate';
import { MIconModule } from '@mercadona/icons';

import { HeaderTitleComponent } from './header-title.component';

/**
 * HeaderTitleModule
 */
@NgModule({
  declarations: [HeaderTitleComponent],
  imports: [
    CommonModule,
    MTranslateModule.forRoot(APP_CONFIG.language),
    MIconModule,
    MButtonModule,
    ProfilingDirectiveModule,
    MAlertModule,
    MLinkModule,
    MBadgeModule,
    HeaderActionsModule
  ],
  exports: [HeaderTitleComponent]
})
export class HeaderTitleModule {}

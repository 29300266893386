import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MercadonaModule } from 'app/mercadona.module';

import { MAlertModule } from '@mercadona/components/alert';

import { HeaderNoticeAlertComponent } from './header-notice-alert.component';

@NgModule({
  declarations: [HeaderNoticeAlertComponent],
  imports: [CommonModule, MAlertModule, MercadonaModule],
  exports: [HeaderNoticeAlertComponent]
})
export class HeaderNoticeAlertModule {}

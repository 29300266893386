import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GlobalCacheService } from '@services/global-cache/global-cache.service';
import { UsersService } from '@services/users/users.service';

/**
 * AdministrationPageComponent
 */
@Component({
  selector: 'app-administration',
  templateUrl: './administration-page.component.html',
  styleUrls: ['./administration-page.component.scss']
})
export class AdministrationPageComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private usersService: UsersService, private globalCacheService: GlobalCacheService) {}

  ngOnInit() {
    this.usersService
      .getUserInfo()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.globalCacheService.user.next(response);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}

/**
 * TypeOfPersonResourceModel
 */
import { TypeOfPersonResourceInterface } from '../../interfaces/type-of-person/type-of-person-resource.interface';

export class TypeOfPersonResourceModel {
  id?: number;
  name?: string;
  isCrew?: boolean;
  isDriver?: boolean;

  constructor(data: TypeOfPersonResourceInterface) {
    this.id = data.id;
    this.name = data.name;
    this.isCrew = data.isCrew;
    this.isDriver = data.isDriver;
  }
}

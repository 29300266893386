import { Injectable } from '@angular/core';

import { DepartmentsRepository } from './departments.repository';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {
  constructor(private departmentsRepository: DepartmentsRepository) {}

  getDepartments(params?: { description?: string; pageSize?: number; firstPage?: number }) {
    return this.departmentsRepository.getDepartments(params);
  }

  getDepartment(departmentId: number) {
    return this.departmentsRepository.getDepartment(departmentId);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { environment } from '@environment';

import { MTranslateService } from '@mercadona/core/translate';

import { IDictionary } from '../interfaces/dictionary.interface';

export abstract class Repository {
  protected basePath = `${environment.apiRootUrl.url}`;
  protected mTranslateService: MTranslateService = this.injector.get(MTranslateService);
  protected http: HttpClient = this.injector.get(HttpClient);
  protected constructor(protected injector: Injector) {}

  protected doRequest<T>(
    method: keyof HttpClient,
    url: string,
    extraParams?: IDictionary<string | number | boolean | string[] | undefined>,
    httpConfig?: IDictionary<unknown>,
    httpHeaders?: HttpHeaders
  ): Observable<T> {
    return this.mTranslateService.activeLang$.pipe(
      switchMap((lang) => {
        const params = this.getParams({
          // TODO -> update when back accept lang instead of locale
          locale: `${lang}-${lang.toUpperCase()}`,
          ...extraParams
        });
        return this.http.request<T>(method, url, { ...httpConfig, params, headers: httpHeaders || {} });
      }),
      take(1)
    );
  }

  private getParams(params: IDictionary<string | number | undefined>) {
    let httpParams = new HttpParams();
    const entries = Object.entries(params);
    for (const [key, value] of entries) {
      if (value !== undefined && value !== null) {
        httpParams = httpParams.set(key, value);
      }
    }
    return httpParams;
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HeaderNoticeAlertModule } from '@components/header-notice-alert/header-notice-alert.module';
import { HeaderTitleModule } from '@components/header-title/header-title.module';
import { HeaderModule } from '@components/header/header.module';
import { MenuModule } from '@components/menu/menu.module';
import { APP_CONFIG } from '@constants';
import { ProfilingDirectiveModule } from '@directives/profiling/profiling.directive.module';
import { environment } from '@environment';
import { AdministrationPageModule } from '@pages/administration/administration-page.module';
import { UserProfilePageModule } from '@pages/user-profile/user-profile-page.module';

import { MErrorHandlerModule } from '@mercadona/core-ui/error-handler';
import { MPageErrorModule } from '@mercadona/core-ui/page-error';
import { MAuthTokenLegacyModule } from '@mercadona/core/auth-token-legacy';
import { MLoggerModule } from '@mercadona/core/logger';
import { MPlatformModule } from '@mercadona/core/platform';
import { MStorageModule } from '@mercadona/core/storage';
import { MTelemetryModule } from '@mercadona/core/telemetry';
import { MTranslateModule } from '@mercadona/core/translate';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTPInterceptorModule } from './interceptors/http-interceptor.module';
import { MercadonaModule } from './mercadona.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    MercadonaModule,
    ProfilingDirectiveModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MErrorHandlerModule.forRoot(),
    MLoggerModule.forRoot({
      logLevel: environment.logLevel
    }),
    MPlatformModule.forRoot({
      appName: APP_CONFIG.appName,
      environment: environment.env
    }),
    MPageErrorModule.forRoot(),
    MAuthTokenLegacyModule.forRoot({
      authOnAppStart: true,
      forceAuthentication: true,
      clientId: environment.configToken.clientId,
      stsServer: environment.configToken.stsServer,
      resource: environment.configToken.audience,
      restServices: [environment.apiRootUrl]
    }),
    MTelemetryModule.forRoot({
      url: environment.telemetryConfig.url,
      traces: environment.telemetryConfig.traces
    }),
    AdministrationPageModule,
    HTTPInterceptorModule,
    DragDropModule,
    UserProfilePageModule,
    HeaderModule,
    MenuModule,
    HeaderTitleModule,
    MStorageModule.forRoot(),
    MTranslateModule.forRoot(APP_CONFIG.language),
    HeaderNoticeAlertModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
